import { Backdrop, Box, CircularProgress, Grid } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { QuickSendContext } from "contexts/QuickSend";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { forwardRef, useContext } from "react";
import { BentoBrand } from "schemas/dashboard";
import { SetAlertType } from "schemas/functions";

import { trackEvent } from "utils/tracking";

import BrandLogo from "../../Shared/BrandLogo";
import BrandTitle from "../../Shared/BrandTitle";
import { BrandCardSource } from "../schema";
import BrandCardActions from "./BrandCardActions";
import styles from "./styles";

interface BrandCardProps {
  brand: BentoBrand;
  setAlert: SetAlertType;
  source?: BrandCardSource;
}

export default forwardRef<HTMLDivElement, BrandCardProps>(
  ({ brand, setAlert, source }, ref) => {
    const { previewLoading } = useContext(QuickSendContext);
    const { handleOpenQuickSendIndividual } = useContext(
      QuickSendDrawerContext,
    );
    const { setBrandNavigationHistory } = useContext(DiscoverViewContext);

    const handleClickCard = () => {
      trackEvent("Brand Card Clicked", {
        "Bento Brand ID": brand?.id,
      });
      setBrandNavigationHistory([brand]);
      handleOpenQuickSendIndividual(
        brand.id,
        "Brand Card",
        undefined,
        true,
        brand,
      );
    };

    return (
      <Box
        ref={ref}
        sx={styles.brandCard}
        key={brand?.id}
        component="div"
        onClick={handleClickCard}
      >
        <CardHeader
          sx={{
            ".MuiCardHeader-avatar": {
              alignSelf: "flex-start",
              mt: 1.5,
            },
          }}
          avatar={<BrandLogo bentoBrand={brand} />}
          title={
            <Grid
              container
              alignItems="center"
              gap={1}
              justifyContent="space-between"
            >
              <BrandTitle bentoBrand={brand} />
              <BrandCardActions
                setAlert={setAlert}
                brand={brand}
                source={source}
              />
            </Grid>
          }
        />

        <Backdrop
          sx={{ color: "#fff", zIndex: 201 }}
          open={previewLoading === brand.id}
        >
          <CircularProgress />
        </Backdrop>
      </Box>
    );
  },
);
