import { Box, Link, Typography } from "@mui/material";

import styles from "./styles";

export default function UserRequestsListFooter() {
  return (
    <Box sx={styles.root}>
      <Typography sx={{ fontSize: 12 }} gutterBottom>
        <strong>Want to request brands on Instagram?</strong>
      </Typography>

      <Typography variant="body2">
        Add{" "}
        <Link
          target="_blank"
          href="https://instagram.com/onbento"
          sx={{ cursor: "pointer", color: "info.main", textDecoration: "none" }}
        >
          @onbento
        </Link>{" "}
        on Instagram and add us as a collaborator on a shared Collection. Every
        brand you bookmark to this collection will be reviewed by us and added
        to the list.
      </Typography>
    </Box>
  );
}
