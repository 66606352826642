import "@emotion/react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { useContext, useState } from "react";

import { trackEvent } from "utils/tracking";

import Collections from "./Collections";
import MoveMenu from "./Collections/MoveMenu";
import NewCollectionDialog from "./Collections/NewCollectionDialog";
import ListHeader from "./ListHeader";
import ProgressBar from "./ProgressBar";
import FavoritesListMobile from "./mobile";
import styles from "./styles";

interface FavoritesListProps {
  closeDialog: () => void;
}

export default function FavoritesList({ closeDialog }: FavoritesListProps) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { totalsMap, selectedCollection, setSelectedCollection } = useContext(
    SavedBrandCollectionsContext,
  );
  const { setBulkBrands } = useContext(SavedBrandContext);

  const [openCollectionDialog, setOpenCollectionDialog] = useState(false);
  const [isBulkMode, setIsBulkMode] = useState(false);

  const getTotal = (collectionId: number) => {
    if (totalsMap[collectionId] !== undefined) {
      return <> ({totalsMap[collectionId]})</>;
    } else {
      return <CircularProgress size={10} sx={{ ml: 1 }} />;
    }
  };

  const toggleBulkMode = () => {
    if (!selectedCollection) return;
    trackEvent("Favorites List - Select Button Clicked", {
      "Collection ID": selectedCollection.id,
    });
    setIsBulkMode((prev) => !prev);
  };

  const handleBack = () => {
    setSelectedCollection(null);
    setIsBulkMode(false);
    setBulkBrands([]);
  };

  const editCollection = () => {
    if (!selectedCollection) return;
    trackEvent("Favorites List - Edit Collection Button Clicked", {
      "Collection ID": selectedCollection.id,
    });
    setOpenCollectionDialog(true);
  };

  const handleCreateNewList = (bulkMove?: boolean) => {
    trackEvent("Favorites List - New List Button Clicked", {
      source: bulkMove ? "Move To Menu" : "New List Button",
    });
    setOpenCollectionDialog(true);
  };

  const collections = (
    <Collections
      selectedCollection={selectedCollection}
      setSelectedCollection={setSelectedCollection}
      isBulkMode={isBulkMode}
      handleCreateNewList={handleCreateNewList}
      getTotal={getTotal}
    />
  );

  const header = (
    <ListHeader
      selectedCollection={selectedCollection}
      toggleBulkMode={toggleBulkMode}
      getTotal={getTotal}
      editCollection={editCollection}
      isBulkMode={isBulkMode}
    />
  );

  const footer = (
    <Grid
      container
      justifyContent={isBulkMode ? "flex-end" : "center"}
      sx={styles.footer}
    >
      {isBulkMode ? (
        <MoveMenu
          handleCreateNewList={handleCreateNewList}
          selectedCollection={selectedCollection}
          setIsBulkMode={setIsBulkMode}
        />
      ) : (
        <ProgressBar />
      )}
    </Grid>
  );

  return (
    <>
      {isMobileScreen ? (
        <FavoritesListMobile
          collections={collections}
          selectedCollection={selectedCollection}
          handleBack={handleBack}
          header={header}
          footer={footer}
        />
      ) : (
        <Box>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={styles.desktopHeader}
          >
            <Typography sx={{ fontSize: { xs: 12, md: 14 } }} component="div">
              {selectedCollection && (
                <IconButton sx={styles.close} onClick={handleBack}>
                  <Box component="i" className="fa-regular fa-arrow-left" />
                </IconButton>
              )}
              {selectedCollection ? "BACK TO SAVED" : "SAVED BRANDS"}
            </Typography>
            {!isMobileScreen && (
              <IconButton sx={styles.close} onClick={closeDialog}>
                <Box component="i" className="fa-regular fa-xmark" />
              </IconButton>
            )}
          </Grid>

          {header}
          {collections}
          {footer}
        </Box>
      )}

      <NewCollectionDialog
        open={openCollectionDialog}
        handleClose={() => setOpenCollectionDialog(false)}
        selectedCollection={selectedCollection}
        setSelectedCollection={setSelectedCollection}
        isBulkMode={isBulkMode}
        setIsBulkMode={setIsBulkMode}
      />
    </>
  );
}
