import { Box, Grid, IconButton, Link, Tooltip } from "@mui/material";
import {
  QuickSendScheduleContext,
  ScheduleTimeDialogSource,
} from "contexts/QuickSendSchedule";
import { useContext } from "react";
import { PreviewMessage } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { TemplateContentType } from "features/Influencer/ContactList/schema";

import { getFollowUpEmailScheduledTime } from "../../PreviewIndividual/helpers";
import styles from "./styles";

interface Props {
  preview: PreviewMessage;
  index: number;
}

const ScheduledTime = ({ preview, index }: Props) => {
  const {
    shortenedSendAt,
    handleRemoveScheduledAt,
    selectedIndex,
    setScheduleDialogSource,
  } = useContext(QuickSendScheduleContext);

  const handleChangeTime = () => {
    setScheduleDialogSource(ScheduleTimeDialogSource.CHANGE);
  };

  if (
    preview?.contentType === TemplateContentType.OUTREACH &&
    shortenedSendAt &&
    selectedIndex === 1
  ) {
    return (
      <Grid
        container
        sx={styles.scheduledAtContainer}
        onClick={(e: CustomEvent) => e.stopPropagation()}
      >
        <Tooltip title="Remove scheduled time">
          <IconButton
            onClick={handleRemoveScheduledAt}
            sx={{ fontSize: 12, pr: 1, ml: -1 }}
          >
            <Box component="i" className="fa-regular fa-circle-xmark" />
          </IconButton>
        </Tooltip>

        <Box component="em">
          Scheduled to send: {shortenedSendAt}
          <Link sx={styles.changeButton} onClick={handleChangeTime}>
            Change
          </Link>
        </Box>
      </Grid>
    );
  } else if (preview?.contentType === TemplateContentType.FOLLOWUP) {
    return (
      <Box sx={styles.scheduledAtContainer} component="em">
        Scheduled to send: {getFollowUpEmailScheduledTime(index)}
      </Box>
    );
  } else {
    return <></>;
  }
};

export default ScheduledTime;
