import { Box, Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { ColumnDialog, TrackingColumnsContext } from "contexts/TrackingColumns";
import { TrackingColumnsFiltersContext } from "contexts/TrackingColumnsFilters";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  AutomatedContactProperty,
  BuiltInContactPropertyName,
} from "schemas/dashboard";
import { routes } from "schemas/routes";

import {
  IMPORT_CONTACTS_CLICKED,
  TABLE_HIDE_COLUMN,
} from "features/Influencer/Tracking/events";

import TableFilterPopupMenu from "../TableFilterPopupMenu";
import SearchBar from "./SearchBar";
import styles from "./styles";

const TableProperties = () => {
  const navigate = useNavigate();
  const { selectedView, trackTable } = useContext(ContactViewContext);
  const { sortedMenuItems, setDialogType, getTitleForHeader } = useContext(
    TrackingColumnsContext,
  );
  const { discoverTab } = useContext(DiscoverViewContext);
  const { handleOpenFilterMenu, filterButtonRef } = useContext(
    TrackingColumnsFiltersContext,
  );
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const totalHiddenColumns = sortedMenuItems?.filter((x) => !x.checked)?.length;
  const props = selectedView?.contactViewProperties;
  let sortedColumn = props?.find((x) => x.sortDirection !== null);
  if (!sortedColumn) {
    sortedColumn = props?.find(
      (x) => x?.contactPropertyName === BuiltInContactPropertyName.createdAt,
    );
  }

  const filtersBeingSet = props?.filter(
    (x) =>
      x.filters?.length > 0 &&
      !(
        selectedView?.name !== "All" &&
        x.contactProperty?.automatedProperty === AutomatedContactProperty.stage
      ),
  );
  const totalFiltersSet = filtersBeingSet?.length;

  const filterNames = filtersBeingSet?.map((x) =>
    getTitleForHeader(x.contactPropertyName || x?.contactProperty?.name),
  );

  const openSideBar = () => {
    trackTable(TABLE_HIDE_COLUMN);
    setDialogType(ColumnDialog.edit);
  };

  const handleNavigateToImport = () => {
    trackTable(IMPORT_CONTACTS_CLICKED);
    navigate(
      `/${routes.trackingView}/${selectedView?.id}/${routes.pitchTrackerImport}`,
    );
  };

  const renderButton = () => {
    if (totalHiddenColumns === 0) {
      return (
        <Button
          onClick={openSideBar}
          size="small"
          disableElevation
          sx={styles.button}
          variant="outlined"
        >
          <Box component="i" className="fa-regular fa-eye" sx={{ mr: 1 }} />
          Hide Columns{" "}
        </Button>
      );
    } else {
      return (
        <Button
          onClick={openSideBar}
          sx={styles.button}
          disableElevation
          size="small"
          variant="outlined"
        >
          <Box
            component="i"
            className="fa-regular fa-eye-slash"
            sx={{ mr: 1 }}
          />{" "}
          {totalHiddenColumns}
          {!isSmallScreen && " Hidden Columns"}
        </Button>
      );
    }
  };

  const renderFilterButton = () => {
    return (
      <Button
        onClick={handleOpenFilterMenu}
        ref={filterButtonRef}
        sx={[styles.button, styles.filterColor]}
        disableElevation
        size="small"
        variant="outlined"
      >
        <Box component="i" className="fa-regular fa-filter" sx={{ mr: 1 }} />{" "}
        {isSmallScreen
          ? "Filter"
          : totalFiltersSet && totalFiltersSet > 0
            ? `Filtered by ${filterNames?.join(", ")}`
            : "Add Filter +"}
      </Button>
    );
  };

  return (
    <>
      <TableFilterPopupMenu />
      <Grid container alignItems="center" sx={{ mb: 2 }} gap={1}>
        <Grid item xs="auto">
          {renderFilterButton()}
        </Grid>
        <Grid item xs="auto">
          {renderButton()}
        </Grid>
        <Grid item xs="auto">
          <Button
            size="small"
            variant="outlined"
            sx={styles.button}
            onClick={handleNavigateToImport}
          >
            Import{isSmallScreen || discoverTab !== null ? "" : " Contacts"}
            <Box
              component="i"
              sx={{ ml: 1 }}
              className="fa-regular fa-upload"
            />
          </Button>
        </Grid>
        <Grid item xs={12} md={discoverTab !== null ? 12 : true}>
          <SearchBar />
        </Grid>
      </Grid>
    </>
  );
};

export default TableProperties;
