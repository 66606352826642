import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { RequestStatus } from "schemas/dashboard";

interface Props {
  status?: RequestStatus;
}

const EmailButton = ({ status }: Props) => {
  if (status === RequestStatus.pending) {
    return (
      <Box>
        <LoadingButton
          sx={{ textTransform: "none" }}
          variant="outlined"
          disableElevation
          size="small"
        >
          Add +
        </LoadingButton>
      </Box>
    );
  } else {
    return (
      <Box>
        <LoadingButton
          sx={{ textTransform: "none" }}
          variant="contained"
          disableElevation
          size="small"
        >
          Email
          <Box
            component="i"
            className="fa-regular fa-envelope"
            sx={{ ml: 1 }}
          />
        </LoadingButton>
      </Box>
    );
  }
};

export default EmailButton;
