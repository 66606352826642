import { Template } from "features/Influencer/ContactList/schema";

import {
  BentoContact,
  NewBentoContact,
  OutreachContactAutomatedStatus,
  OutreachContactStatus,
} from "./dashboard";
import { EmailSettings } from "./email";

export const OutreachStatusLabel: { [key in OutreachContactStatus]: string } = {
  [OutreachContactStatus.pending]: "Scheduled",
  [OutreachContactStatus.bounced]: "Bounced",
  [OutreachContactStatus.no_response]: "Sent",
  [OutreachContactStatus.received_response]: "Contact has responded",
  [OutreachContactStatus.interested]: "Contact Interested",
  [OutreachContactStatus.not_interested]: "Contact Not Interested",
};

export const OutreachStatusOptions = [
  OutreachContactStatus.received_response,
  OutreachContactStatus.interested,
  OutreachContactStatus.not_interested,
];

export const OutreachStatusFilterOptions = [
  OutreachContactStatus.received_response,
  OutreachContactStatus.no_response,
  OutreachContactStatus.pending,
];

export const NoResponseStatus = [
  OutreachContactAutomatedStatus.invalid,
  OutreachContactAutomatedStatus.contacted,
  OutreachContactAutomatedStatus.automated,
];

export type OutreachEmailErrors = {
  outreachSubject?: boolean;
  outreachBody?: number;
  mergeTag?: number;
};

export type Conditions = {
  skipsBusinessHour?: boolean;
  skipsRecentContact?: boolean;
};

export type PreviewQuickSendBody = {
  bentoBrandId: number;
  emailTemplates: Template[];
  previewContact?: BentoContact | NewBentoContact;
  previousContactName?: string;
  source?: string;
};

export type SendOutreachApiRouteResponse = {
  completedTaskIds: number[];
  emailSetting: EmailSettings;
};

export type HandleFetchPreviewFunction = (
  brandId: number,
  bentoContact?: BentoContact | null,
  specificTemplates?: Template[],
) => void;

export type HandleChangePreviewFunction = (
  name: string,
  text: string | undefined,
  index: number,
) => void;
