import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  NumbersMap,
  SavedBrandCollection,
  SavedCollectionsMap,
} from "schemas/dashboard";

import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

type NameMap = { [key: number]: string };

interface SavedBrandCollectionsContextInterface {
  collections: SavedBrandCollection[];
  setCollections: Dispatch<SetStateAction<SavedBrandCollection[]>>;
  totalsMap: NumbersMap;
  collectionsMap: SavedCollectionsMap;
  setCollectionsMap: Dispatch<SetStateAction<SavedCollectionsMap>>;
  setTotalsMap: Dispatch<SetStateAction<NumbersMap>>;
  pagesMap: NumbersMap;
  setPagesMap: Dispatch<SetStateAction<NumbersMap>>;
  refreshList: () => void;
  initializeList: (collection: SavedBrandCollection) => void;
  createOrUpdateNewCollection: (
    name: string,
    collectionId?: number,
    isBulkMode?: boolean,
  ) => Promise<void | SavedBrandCollection>;
  deleteCollection: (collectionId: number) => void;
  fetchLoading: number;
  fetchSavedBrandsForCollection: (collectionId: number, page?: number) => void;
  collectionsNames: NameMap;
  setCollectionsNames: Dispatch<SetStateAction<NameMap>>;
  selectedCollection: SavedBrandCollection | null;
  setSelectedCollection: Dispatch<SetStateAction<SavedBrandCollection | null>>;
}

const PER_PAGE = 20;

const defaultInterface = {
  collections: [],
  setCollections: defaultContextMissingFunction,
  totalsMap: {},
  collectionsMap: {},
  pagesMap: {},
  setCollectionsMap: defaultContextMissingFunction,
  setTotalsMap: defaultContextMissingFunction,
  setPagesMap: defaultContextMissingFunction,
  refreshList: defaultContextMissingFunction,
  initializeList: defaultContextMissingFunction,
  createOrUpdateNewCollection: defaultContextMissingFunction,
  deleteCollection: defaultContextMissingFunction,
  fetchLoading: -1,
  fetchSavedBrandsForCollection: defaultContextMissingFunction,
  collectionsNames: {},
  setCollectionsNames: defaultContextMissingFunction,
  selectedCollection: null,
  setSelectedCollection: defaultContextMissingFunction,
};

const SavedBrandCollectionsContext =
  createContext<SavedBrandCollectionsContextInterface>(defaultInterface);

interface SavedBrandCollectionsProviderProps {
  children: React.ReactNode;
}

const SavedBrandCollectionsProvider = ({
  children,
}: SavedBrandCollectionsProviderProps) => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const { setErrorAlert, setAlert } = useContext(AlertContext);

  const [collections, setCollections] = useState<SavedBrandCollection[]>([]);
  // "0" represent collections of brands that has no associated SavedBrandCollection's ID.
  const [collectionsMap, setCollectionsMap] = useState<SavedCollectionsMap>({});
  const [collectionsNames, setCollectionsNames] = useState<NameMap>({});
  const [selectedCollection, setSelectedCollection] =
    useState<SavedBrandCollection | null>(null);

  const [pagesMap, setPagesMap] = useState<NumbersMap>({});
  const [totalsMap, setTotalsMap] = useState<NumbersMap>({});
  const [fetchLoading, setFetchLoading] = useState(-1);

  const fetchCollections = async () => {
    if (!currentOrg) return;
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg.id}/saved-brand-collections`,
        "GET",
      );
      setCollections(res.savedBrandCollections);
      if (res.savedBrandCollections?.length > 0) {
        for (const collection of res.savedBrandCollections) {
          initializeList(collection);
        }
      }
      initializeList({ id: 0, name: "All Saved" });
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const fetchSavedBrandsForCollection = async (
    collectionId: number,
    pageToFetch?: number,
  ) => {
    let page = pageToFetch;
    if (!pageToFetch) {
      page = pagesMap[collectionId];
    }
    if (!currentOrg?.id) return;
    setFetchLoading(collectionId);
    let url = `/api/organization/${currentOrg?.id}/saved-brands?page=${page}&per_page=${PER_PAGE}`;
    if (collectionId > 0) {
      url += `&saved_brand_collection_id=${collectionId}`;
    }
    try {
      const res = await fetcherAuth(getToken, url, "GET");
      if (page === 1) {
        setCollectionsMap((prev) => ({
          ...prev,
          [collectionId]: res.brands,
        }));
      } else {
        setCollectionsMap((prev) => ({
          ...prev,
          [collectionId]: [...(prev[collectionId] || []), ...res.brands],
        }));
      }
      setTotalsMap((prev) => ({
        ...prev,
        [collectionId]: res.total,
      }));
      if (pageToFetch !== undefined && pageToFetch !== pagesMap[collectionId]) {
        setPagesMap((prev) => ({
          ...prev,
          [collectionId]: pageToFetch,
        }));
      }
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setFetchLoading(-1);
    }
  };

  const createOrUpdateNewCollection = async (
    name: string,
    collectionId?: number,
    isBulkMode?: boolean,
  ) => {
    if (!currentOrg?.id) return;
    let url = collectionId
      ? `/api/organization/${currentOrg.id}/saved-brand-collections/${collectionId}`
      : `/api/organization/${currentOrg.id}/saved-brand-collections`;
    try {
      const res = await fetcherAuth(
        getToken,
        url,
        collectionId ? "PUT" : "POST",
        {},
        { name },
      );
      if (collectionId) {
        setCollections((prev) => {
          const copy = makeDeepCopy(prev);
          const index = copy.findIndex(
            (x: SavedBrandCollection) => x.id === collectionId,
          );
          if (index > -1) {
            copy[index] = res.savedBrandCollection;
          }
          return copy;
        });
        if (!isBulkMode)
          setAlert("Successfully updated your collection", "success");
      } else {
        setCollections((prev) => [...prev, res.savedBrandCollection]);
        initializeList(res.savedBrandCollection);
        if (!isBulkMode)
          setAlert("Successfully created your collection", "success");
      }
      return res.savedBrandCollection;
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const deleteCollection = async (collectionId: number) => {
    if (!currentOrg?.id) return;
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg.id}/saved-brand-collections/${collectionId}`,
        "DELETE",
      );
      setCollections((prev) => prev?.filter((x) => x.id !== collectionId));
      setCollectionsMap((prev) => {
        if (collectionId in prev) {
          delete prev[collectionId];
        }
        return prev;
      });
      await refreshList();
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const refreshList = async () => {
    const brandsToFetch = Object.keys(collectionsMap).map((c) =>
      fetchSavedBrandsForCollection(Number(c), 1),
    );
    return await Promise.all(brandsToFetch);
  };

  const initializeList = (collection: SavedBrandCollection) => {
    setPagesMap((prev) => {
      return collection.id in prev ? prev : { ...prev, [collection.id]: 1 };
    });
    setTotalsMap((prev) => {
      return collection.id in prev
        ? prev
        : { ...prev, [collection.id]: undefined };
    });
    setCollectionsMap((prev) => {
      return collection.id in prev
        ? prev
        : { ...prev, [collection.id]: undefined };
    });
  };

  useEffect(() => {
    const fetchBrands = async () => {
      const collectionsWithoutBrands = Object.keys(collectionsMap)?.filter(
        (key) => collectionsMap[Number(key)] === undefined,
      );
      const brandsToFetch = collectionsWithoutBrands.map((c) =>
        fetchSavedBrandsForCollection(Number(c)),
      );
      await Promise.all(brandsToFetch);
    };

    fetchBrands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Object.keys(collectionsMap)?.length]);

  useEffect(() => {
    fetchCollections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  useEffect(() => {
    const copy = makeDeepCopy(collectionsNames);
    for (const collection of collections) {
      if (!(collection.id in copy)) {
        copy[collection.id] = collection.name;
      }
    }
    setCollectionsNames(copy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections?.length]);

  return (
    <SavedBrandCollectionsContext.Provider
      value={{
        collections,
        setCollections,
        totalsMap,
        collectionsMap,
        setTotalsMap,
        setCollectionsMap,
        refreshList,
        initializeList,
        createOrUpdateNewCollection,
        fetchSavedBrandsForCollection,
        deleteCollection,
        fetchLoading,
        pagesMap,
        setPagesMap,
        collectionsNames,
        setCollectionsNames,
        selectedCollection,
        setSelectedCollection,
      }}
    >
      {children}
    </SavedBrandCollectionsContext.Provider>
  );
};

export { SavedBrandCollectionsProvider, SavedBrandCollectionsContext };
