import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ContactProperty, TitleMap } from "schemas/dashboard";

import {
  BuiltInContactPropertyNames,
  ContactViewProperty,
  PropertyFilter,
  PropertySortDirection,
} from "features/Influencer/Tracking/schema";
import { fetcherAuth } from "utils/api";
import { makeDeepCopy } from "utils/updateLocalState";

import { AlertContext } from "./Alert";
import { ContactViewContext } from "./ContactView";
import { ContactViewSearchContext } from "./ContactViewSearch";
import { OrganizationUserContext } from "./Organization";

export type CreateColumnBody = {
  contactPropertyId?: number;
  contactPropertyName?: string;
};

export type UpdateColumnBody = {
  name?: string;
  filter?: PropertyFilter;
  sortDirection?: PropertySortDirection | null;
};

export enum ColumnDialog {
  add = "add",
  edit = "edit",
}

export type SelectMenuItem = {
  name: string;
  id?: number;
  checked: boolean;
  title: string;
};

interface TrackingColumnsContextInterface {
  handleHideColumn: (propertyId: number) => void;
  handleCreateColumn: (data: CreateColumnBody, refetch?: boolean) => void;
  handleUpdateColumn: (
    prop: ContactViewProperty,
    data: UpdateColumnBody,
    refetchView?: boolean,
  ) => void;
  dialogType: ColumnDialog | null;
  setDialogType: Dispatch<SetStateAction<ColumnDialog | null>>;
  sortedMenuItems: SelectMenuItem[];
  setContactProperties: Dispatch<SetStateAction<ContactProperty[]>>;
  getTitleForHeader: (title?: string) => string;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  handleHideColumn: defaultContextMissingFunction,
  handleCreateColumn: defaultContextMissingFunction,
  handleUpdateColumn: defaultContextMissingFunction,
  dialogType: null,
  setDialogType: defaultContextMissingFunction,
  sortedMenuItems: [],
  setContactProperties: defaultContextMissingFunction,
  getTitleForHeader: defaultContextMissingFunction,
};

const TrackingColumnsContext =
  createContext<TrackingColumnsContextInterface>(defaultInterface);

interface TrackingColumnsProviderProps {
  children: React.ReactNode;
}
const TrackingColumnsProvider = ({
  children,
}: TrackingColumnsProviderProps) => {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setErrorAlert } = useContext(AlertContext);
  const { selectedView, setSelectedView } = useContext(ContactViewContext);
  const { contactViewParams, handleSearch, setViewLoading } = useContext(
    ContactViewSearchContext,
  );
  const [dialogType, setDialogType] = useState<ColumnDialog | null>(null);

  const builtIns: string[] = Object.values(BuiltInContactPropertyNames);
  const props = selectedView?.contactViewProperties || [];
  const selectedNames = props
    ?.filter((x) => x.displayOrder !== undefined && x.displayOrder >= 0)
    .map((x) => x.contactPropertyName);
  const selectedIds = props
    ?.filter((x) => x.displayOrder !== undefined && x.displayOrder >= 0)
    ?.map((x) => x.contactPropertyId);

  const [contactProperties, setContactProperties] = useState<ContactProperty[]>(
    [],
  );

  const getTitleForHeader = (title?: string) => {
    if (!title) return "";
    if (title in TitleMap) {
      return TitleMap[title];
    } else {
      return title;
    }
  };

  const handleCreateColumn = async (
    data: CreateColumnBody,
    refetch?: boolean,
  ) => {
    try {
      setViewLoading(true);
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-view-properties`,
        "POST",
        {},
        { contactViewId: selectedView?.id, ...data },
      );
      if (refetch && selectedView) {
        handleSearch({ query: contactViewParams.query, page: 1 });
      } else {
        const newProperty = res.contactViewProperty;
        const copy = makeDeepCopy(selectedView);
        if (selectedView?.contactViewProperties) {
          const updatedProperties = [
            ...selectedView?.contactViewProperties,
            newProperty,
          ];
          const sortedProperties = updatedProperties.sort(
            (a, b) => a.displayOrder - b.displayOrder,
          );
          copy.contactViewProperties = sortedProperties;
          setSelectedView(copy);
        }
        setViewLoading(false);
      }
    } catch (error) {
      setErrorAlert(error);
      setViewLoading(false);
    }
  };

  const handleUpdateColumn = async (
    contactViewProperty: ContactViewProperty,
    updatedData: UpdateColumnBody,
    refetchView = false,
  ) => {
    const data = updatedData;
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-view-properties/${contactViewProperty.id}`,
        "PUT",
        {},
        { ...data, displayOrder: contactViewProperty.displayOrder },
      );
      if (refetchView) {
        // Refetch view for new rows on table after filter is done
        handleSearch({ query: contactViewParams.query, page: 1 });
      } else {
        setViewLoading(false);
      }
    } catch (error) {
      setErrorAlert(error);
      setViewLoading(false);
    }
  };

  const handleHideColumn = async (propertyId: number) => {
    setViewLoading(true);
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-view-properties/${propertyId}`,
        "DELETE",
      );
      const copy = makeDeepCopy(selectedView);
      const updated = copy.contactViewProperties?.filter(
        (x: ContactViewProperty) => x.id !== propertyId,
      );
      copy.contactViewProperties = updated;
      setSelectedView(copy);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setViewLoading(false);
    }
  };

  const sortedMenuItems = useMemo(() => {
    const transformed: SelectMenuItem[] = [];
    for (const name of builtIns) {
      transformed?.push({
        name,
        checked: selectedNames?.includes(name),
        title: getTitleForHeader(name),
      });
    }
    for (const prop of contactProperties) {
      transformed?.push({
        name: prop?.name,
        id: prop?.id,
        checked: selectedIds?.includes(prop.id),
        title: getTitleForHeader(prop.name),
      });
    }
    const sorted = transformed.sort((a, b) => a.title.localeCompare(b.title));
    return sorted;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactProperties, selectedIds]);

  const getContactProperties = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-properties`,
      );
      setContactProperties(res.contactProperties);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  useEffect(() => {
    if (currentOrg?.id) {
      getContactProperties();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return (
    <TrackingColumnsContext.Provider
      value={{
        handleHideColumn,
        handleCreateColumn,
        handleUpdateColumn,
        dialogType,
        setDialogType,
        sortedMenuItems,
        setContactProperties,
        getTitleForHeader,
      }}
    >
      {children}
    </TrackingColumnsContext.Provider>
  );
};

export { TrackingColumnsProvider, TrackingColumnsContext };
