import { Grid, Skeleton } from "@mui/material";
import { forwardRef } from "react";
import { BentoBrand } from "schemas/dashboard";

import SelectedBrand from "./SelectedBrand";

const styles = {
  container: {
    maxHeight: "calc(100vh - 100px)",
    overflow: "auto",
    pb: 10,
  },
};

function SkeletonBrand() {
  return (
    <Grid container sx={{ p: 2 }}>
      <Grid item xs={2}>
        <Skeleton variant="rounded" width={50} height={50} />
      </Grid>

      <Grid container item xs gap={1}>
        <Skeleton animation="wave" variant="rounded" height={20} width="100%" />
        <Skeleton animation="wave" variant="rounded" height={20} width="100%" />
      </Grid>
    </Grid>
  );
}

const REF_OFFSET = 9;

interface IProps {
  displayedBrands: BentoBrand[];
  loading: boolean;
  collectionId: number;
  isBulkMode: boolean;
}

const BrandList = forwardRef(
  ({ displayedBrands, loading, collectionId, isBulkMode }: IProps, ref) => {
    return (
      <Grid container sx={styles.container}>
        {displayedBrands.map((bentoBrand: BentoBrand, index: number) => (
          <SelectedBrand
            key={bentoBrand.id}
            ref={displayedBrands.length - REF_OFFSET === index ? ref : null}
            bentoBrand={bentoBrand}
            collectionId={collectionId}
            isBulkMode={isBulkMode}
            index={index}
          />
        ))}
        {loading && (
          <>
            <SkeletonBrand />
            <SkeletonBrand />
            <SkeletonBrand />
          </>
        )}
      </Grid>
    );
  },
);

export default BrandList;
