import { LoadingButton } from "@mui/lab";
import { Box, Button, Chip, Grid, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BENTO_BRAND_CATEGORIES } from "schemas/dashboard";
import { Map } from "schemas/functions";
import { CustomEvent } from "schemas/functions";
import { routes } from "schemas/routes";

import { makeDeepCopy } from "utils/updateLocalState";

interface Props {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  loading: boolean;
  isProfilePage?: boolean;
}

const styles = {
  hover: {
    "&:hover": {
      color: "white",
      backgroundColor: "primary.main",
    },
  },
};
const EXEMPT_CATEGORIES = ["other_beauty_personal_care"];

const CategoryForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  loading,
  isProfilePage = false,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [categoriesValidation, setCategoriesValidation] = useState<
    string | undefined
  >();

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();
    saveOnboardingForm(onboardingForm, "category");
  };

  const handleBack = () => {
    navigate(`/${routes.aboutOnboarding}${location.search}`);
  };

  const getCategoriesToPickFrom = () => {
    let categories: { label: string; key: string }[] = [];
    for (const category of BENTO_BRAND_CATEGORIES) {
      if (!category.subCategories || category.subCategories?.length === 0) {
        categories.push(category.mainCategory);
      } else {
        categories = [...categories, ...(category.subCategories || [])];
      }
    }
    return categories?.filter((x) => !EXEMPT_CATEGORIES?.includes(x.key));
  };

  const categoriesToPickFrom = getCategoriesToPickFrom();

  const selectChip = (label: string) => {
    const copy = makeDeepCopy(onboardingForm);
    const index = copy.categories?.findIndex((x: string) => x === label);
    if (index > -1) {
      copy.categories.splice(index, 1);
      if (categoriesValidation) {
        setCategoriesValidation("");
      }
    } else {
      if (copy.categories?.length >= 3 && !isProfilePage) {
        setCategoriesValidation("Please pick at most 3 categories");
        return;
      }
      if (!copy.categories) copy.categories = [];
      copy.categories.push(label);
    }
    setOnboardingForm(copy);
  };

  const getBgColor = (label: string) => {
    if (!onboardingForm?.categories) return {};
    if (onboardingForm?.categories?.includes(label)) {
      return { backgroundColor: "primary.main", color: "white" };
    }
    return {};
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Typography variant="h3" sx={{ mb: 3 }}>
          <Box
            component="i"
            color="secondary.dark"
            sx={{ pr: 2 }}
            className="fa-solid fa-layer-group"
          />
          {isProfilePage ? "Your Brand Types" : "Select 3 Brand Types"}
        </Typography>
        {isProfilePage
          ? "What are your preferred types of brands to work with?"
          : "What are your top 3 preferred types of brands to work with?"}{" "}
        *
        <Typography sx={{ color: "error.main", my: 1 }}>
          <em>{categoriesValidation}</em>
        </Typography>
        <Grid container sx={{ my: 2 }} gap={2}>
          {categoriesToPickFrom?.map((chip, index) => (
            <Chip
              key={index}
              sx={[getBgColor(chip.label), styles.hover]}
              label={chip.label}
              onClick={() => selectChip(chip.label)}
            />
          ))}
        </Grid>
        {!isProfilePage && (
          <Grid item xs="auto" container columnGap={1}>
            <Grid item xs="auto">
              <Button onClick={handleBack} disableElevation>
                Back
              </Button>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs="auto">
              <LoadingButton
                disableElevation
                variant="contained"
                type="submit"
                loading={loading}
                disabled={
                  !onboardingForm?.categories ||
                  onboardingForm?.categories?.length === 0
                }
              >
                Next
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default CategoryForm;
