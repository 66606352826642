import { useAuth } from "@clerk/clerk-react";
import { Box, CircularProgress, Fab } from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useEffect, useState } from "react";
import { unstable_usePrompt } from "react-router-dom";
import { OrganizationProfile } from "schemas/dashboard";
import { Map } from "schemas/functions";

import { PAGE_VISITED } from "constants/trackingProps";
import AboutForm from "features/Auth/Onboarding/AboutForm";
import CategoryForm from "features/Auth/Onboarding/CategoryForm";
import CustomizeCategoryEmailForm from "features/Auth/Onboarding/CustomizeCategoryEmail";
import CustomizePartOneEmail from "features/Auth/Onboarding/CustomizePartOneEmail";
import CustomizePartTwoEmail from "features/Auth/Onboarding/CustomizePartTwoEmail";
import RecommendationForm from "features/Auth/Onboarding/RecommendationForm";
import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "./styles";

const Profile = () => {
  const { setAlert } = useContext(AlertContext);
  const { getToken } = useAuth();
  const { currentUser, setCurrentUser, currentOrg, profile, setProfile } =
    useContext(OrganizationUserContext);
  const [loading, setLoading] = useState(false);
  const { tabWidth, discoverTab } = useContext(DiscoverViewContext);

  const [onboardingForm, setOnboardingForm] = useState<Map>({});
  const [isDirty, setIsDirty] = useState(false);

  const saveOnboardingForm = async () => {
    const onboardingFormReq = { ...onboardingForm, page: "Profile" };
    const unfilledRequireFields = validateRequiredFields();
    if (unfilledRequireFields?.length > 0) {
      setAlert(
        `Please fill out required fields in ${unfilledRequireFields?.join(
          ", ",
        )}`,
        "warning",
      );
      return;
    }
    try {
      setLoading(true);
      const { organizationProfile } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/onboarding`,
        "PUT",
        {},
        onboardingFormReq,
      );
      setProfile((prev) => ({
        ...prev,
        ...organizationProfile,
      }));
      setCurrentUser((prev) => {
        if (prev) {
          prev.name = onboardingForm.name;
        }
        return prev;
      });
      setAlert("Sucessfully updated profile", "success");
      setIsDirty(false);
    } catch (error) {
      setAlert(
        error?.message ||
          "Unable to save your information. Please reload and try again",
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  unstable_usePrompt({
    message:
      "You have unsaved changes. Are you sure you want to navigate away from this page?",
    when: ({ currentLocation, nextLocation }) =>
      Boolean(isDirty) && currentLocation.pathname !== nextLocation.pathname,
  });

  const hasDifference = () => {
    if (!profile || Object.keys(onboardingForm)?.length === 0) return;
    for (const key in profile) {
      if (
        key in onboardingForm &&
        JSON.stringify(profile[key as keyof OrganizationProfile]) !==
          JSON.stringify(onboardingForm[key])
      ) {
        return true;
      }
    }
    return false;
  };

  const validateRequiredFields = () => {
    const requiredKeys: Map = {
      name: "Name",
      categories: "Selected Categories",
      addressComponents: "Location",
      preferredBrandSize: "Preferred Brand Size",
      introduction: "About Me",
    };
    const missingKeys = [];
    for (const key in requiredKeys) {
      const value = onboardingForm[key];
      if (
        value?.length === 0 ||
        // @ts-ignore: Guaranteed type is string
        (typeof value === "string" && value?.trim()?.length === 0)
      ) {
        missingKeys.push(requiredKeys[key]);
      }
    }
    return missingKeys;
  };

  useEffect(() => {
    if (!isDirty && hasDifference()) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingForm]);

  useEffect(() => {
    if (profile?.id) {
      const copy = makeDeepCopy(profile);
      copy.name = currentUser?.name;
      setOnboardingForm(copy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.id]);

  useEffect(() => {
    trackEvent(PAGE_VISITED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={styles.page}>
      <AboutForm
        onboardingForm={onboardingForm}
        setOnboardingForm={setOnboardingForm}
        saveOnboardingForm={saveOnboardingForm}
        loading={loading}
        isProfilePage={true}
      />
      <CategoryForm
        onboardingForm={onboardingForm}
        setOnboardingForm={setOnboardingForm}
        saveOnboardingForm={saveOnboardingForm}
        loading={loading}
        isProfilePage={true}
      />

      <CustomizeCategoryEmailForm
        onboardingForm={onboardingForm}
        setOnboardingForm={setOnboardingForm}
        saveOnboardingForm={saveOnboardingForm}
        loading={loading}
        isProfilePage={true}
      />
      <RecommendationForm
        onboardingForm={onboardingForm}
        setOnboardingForm={setOnboardingForm}
        saveOnboardingForm={saveOnboardingForm}
        loading={loading}
        isProfilePage={true}
      />
      <CustomizePartOneEmail
        onboardingForm={onboardingForm}
        setOnboardingForm={setOnboardingForm}
        saveOnboardingForm={saveOnboardingForm}
        loading={loading}
        isProfilePage={true}
      />
      <CustomizePartTwoEmail
        onboardingForm={onboardingForm}
        setOnboardingForm={setOnboardingForm}
        saveOnboardingForm={saveOnboardingForm}
        loading={loading}
        isProfilePage={true}
      />

      <Box sx={styles.button(tabWidth, discoverTab !== null)}>
        <Fab
          disabled={loading}
          variant="extended"
          color="primary"
          onClick={saveOnboardingForm}
        >
          <Box component="i" className="fa-regular fa-save" sx={{ mr: 1 }} />
          {loading ? <CircularProgress size={20} /> : "Save"}
        </Fab>
      </Box>
    </Box>
  );
};

export default Profile;
