import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { forwardRef, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { BentoBrand, RequestStatus } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import EmailButton from "components/EmailButton";
import { RequestStatusColor } from "utils/color";
import { generateInitials } from "utils/string";

import styles from "./styles";

interface SelectedBrandProps {
  bentoBrand: BentoBrand;
  handleDelete: (e: CustomEvent) => void;
  status?: RequestStatus;
  label?: string;
  removeLoading: boolean;
  bcid?: number;
}

const SelectedBrand = forwardRef(
  (
    {
      bentoBrand,
      handleDelete,
      status,
      label,
      removeLoading,
      bcid,
    }: SelectedBrandProps,
    ref,
  ) => {
    const { setOpenAddNewContact } = useContext(QuickSendDrawerContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const { handleOpenQuickSendIndividual, quickSendBrandId } = useContext(
      QuickSendDrawerContext,
    );

    const openQuickSend = () => {
      if (bcid) {
        searchParams.set("bcid", bcid?.toString());
        setSearchParams(searchParams);
      }
      handleOpenQuickSendIndividual(
        bentoBrand?.id,
        "User Requests List",
        undefined,
        true,
      );
      if (status === RequestStatus.pending) {
        setOpenAddNewContact(true);
      }
    };

    const goToInstagram = (e: CustomEvent) => {
      e.stopPropagation();
      window.open(
        `https://instagram.com/${bentoBrand.instagramUsername}`,
        "_blank",
      );
    };

    const isSelected = quickSendBrandId === bentoBrand?.id;

    return (
      <Box
        ref={ref}
        sx={[styles.brandContainer, isSelected && styles.selected]}
        onClick={openQuickSend}
        key={`cart-brand-${bentoBrand.id}`}
      >
        {removeLoading ? (
          <CircularProgress size={20} />
        ) : (
          <IconButton onClick={handleDelete} sx={styles.removeButton}>
            <Box component="i" className="fa-regular fa-circle-xmark" />
          </IconButton>
        )}
        <Box sx={styles.brandImage}>
          {bentoBrand.logoUrl ? (
            <img
              src={bentoBrand.logoUrl}
              alt={`${bentoBrand.brandName} logo`}
            />
          ) : (
            <Avatar sx={styles.avatar}>
              {generateInitials(bentoBrand.brandName)}
            </Avatar>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={styles.brandName}>{bentoBrand.brandName}</Box>

          {bentoBrand.instagramUsername && (
            <Link target="_blank" onClick={goToInstagram} sx={{ fontSize: 12 }}>
              @{bentoBrand.instagramUsername}
            </Link>
          )}

          <Grid item xs={12} sx={{ my: 0.5 }}>
            {status && (
              <Typography
                variant="body2"
                sx={{
                  color: RequestStatusColor[status],
                }}
              >
                <>
                  {status === RequestStatus.found && (
                    <Box component="i" className="fa-regular fa-check" />
                  )}{" "}
                  <strong>{label}</strong>
                </>
              </Typography>
            )}
          </Grid>
        </Box>

        <EmailButton status={status} />
      </Box>
    );
  },
);

export default SelectedBrand;
