import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { SubscriptionContext } from "contexts/Subscription";
import { useContext } from "react";
import { Plans } from "schemas/payments";

interface ManageDialogProps {
  plan: Plans;
  open: boolean;
  loading: boolean;
  onClose: () => void;
  openCancel: () => void;
  handleCheckout: (
    plan: Plans,
    flow?: string,
    openTarget?: "_self" | "_blank",
  ) => void;
}

export default function ManageDialog({
  plan,
  open,
  onClose,
  handleCheckout,
  openCancel,
  loading,
}: ManageDialogProps) {
  const { subscription } = useContext(SubscriptionContext);
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <IconButton
        sx={{
          color: "primary.main",
          fontSize: 14,
          position: "absolute",
          right: 10,
          top: 10,
        }}
        onClick={onClose}
      >
        <Box component="i" className="fa-regular fa-xmark" />
      </IconButton>
      <DialogTitle>Manage Current Plan</DialogTitle>
      <DialogContent>
        <Typography paragraph>
          You are currently on the {subscription?.subscription.planName} plan.
          Use the link below to change your current plan, modify your payment
          method, and see invoice history.
        </Typography>
        <LoadingButton
          loading={loading}
          variant="outlined"
          onClick={() => {
            handleCheckout(plan, undefined, "_blank");
          }}
        >
          Manage Plan
        </LoadingButton>
        {!subscription?.subscription.cancelAt && (
          <>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" paragraph>
              Cancel Subscription
            </Typography>
            <Typography paragraph>
              By ending your subscription, you will lose access to your{" "}
              {subscription?.subscription.planName} plan benefits.
            </Typography>
            <Button variant="outlined" color="secondary" onClick={openCancel}>
              Cancel Subscription
            </Button>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
