import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomEvent, Map } from "schemas/functions";
import { routes } from "schemas/routes";

import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "../styles";

interface CustomizeCategoryProps {
  onboardingForm: Map;
  setOnboardingForm: Dispatch<SetStateAction<Map>>;
  saveOnboardingForm: (onboardingForm: Map, pageType: string) => void;
  loading: boolean;
  isProfilePage?: boolean;
}

const CustomizeCategoryEmailForm = ({
  onboardingForm,
  setOnboardingForm,
  saveOnboardingForm,
  loading,
  isProfilePage,
}: CustomizeCategoryProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    if (onboardingForm.hasPitchEmail) {
      navigate(`/${routes.pitchOnboarding}${location.search}`);
    } else {
      navigate(`/${routes.customizePartTwoOnboarding}${location.search}`);
    }
  };

  const handleSkip = () => {
    trackEvent("Onboarding Form - Skip Button Pressed", { page: "categories" });
    navigate(`/${routes.selectBrandOnboarding}${location.search}`);
  };

  const handleChange = (category: string) => (e: CustomEvent) => {
    const { name, value } = e.target;
    const copy = makeDeepCopy(onboardingForm);
    if (!copy.categorySpecificDetails) {
      copy.categorySpecificDetails = {};
    }
    if (!copy.categorySpecificDetails[category]) {
      copy.categorySpecificDetails[category] = {};
    }
    copy.categorySpecificDetails[category][name] = value;
    setOnboardingForm(copy);
  };

  const handleSubmit = (event: CustomEvent) => {
    event.preventDefault();

    saveOnboardingForm(onboardingForm, "categories");
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        {!isProfilePage && (
          <Typography variant="h3" sx={{ mb: 3 }}>
            <Box
              component="i"
              color="secondary.dark"
              sx={{ pr: 2 }}
              className="fa-solid fa-envelope"
            />
            Let us <i>really</i> help you customize every email
          </Typography>
        )}

        <Grid container sx={styles.fieldContainer} rowGap={2}>
          {(onboardingForm.categories || []).map((category: string) => (
            <Grid item xs={12} key={category}>
              <Typography paragraph>
                For pitching to brands in <strong>{category}</strong>, share:
              </Typography>
              <Box sx={{ mx: 1 }}>
                <FormHelperText sx={styles.helperText}>
                  What's something unique about yourself that will help you
                  stand out to brands in {category}?
                </FormHelperText>
                <TextField
                  sx={styles.textField}
                  fullWidth
                  name="about_me"
                  placeholder="I absolutely love ..."
                  onChange={handleChange(category)}
                  multiline
                  minRows={2}
                  value={
                    onboardingForm?.categorySpecificDetails?.[category]
                      ?.about_me || ""
                  }
                />
                <FormHelperText sx={styles.helperText}>
                  Any past brands you've worked with in {category}:
                </FormHelperText>
                <TextField
                  sx={styles.textField}
                  fullWidth
                  name="past_brands"
                  placeholder="Share brand names ..."
                  onChange={handleChange(category)}
                  value={
                    onboardingForm?.categorySpecificDetails?.[category]
                      ?.past_brands || ""
                  }
                />
                <FormHelperText sx={styles.helperText}>
                  Can you share a link to a sample video in {category}?
                </FormHelperText>
                <TextField
                  sx={styles.textField}
                  fullWidth
                  name="sample_video"
                  placeholder="Enter social media link"
                  onChange={handleChange(category)}
                  value={
                    onboardingForm?.categorySpecificDetails?.[category]
                      ?.sample_video || ""
                  }
                />
              </Box>
            </Grid>
          ))}
        </Grid>

        {!isProfilePage && (
          <Grid item xs="auto" container columnGap={1}>
            <Grid item xs="auto">
              <Button onClick={handleBack} disableElevation>
                Back
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button onClick={handleSkip} disableElevation color="secondary">
                Skip
              </Button>
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs="auto">
              <LoadingButton
                disableElevation
                variant="contained"
                type="submit"
                loading={loading}
              >
                Next
              </LoadingButton>
            </Grid>
          </Grid>
        )}
      </form>
    </>
  );
};

export default CustomizeCategoryEmailForm;
