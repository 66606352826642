import { useAuth } from "@clerk/clerk-react";
import {
  Alert,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { OutreachContactsContext } from "contexts/OutreachContacts";
import { UserIntegrationsContext } from "contexts/UserIntegrations";
import { useContext, useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Email,
  GmailThread,
  OutreachContactAutomatedStatus,
} from "schemas/dashboard";
import {
  EmailDraft,
  EmailThreadSetting,
  MessageAttachment,
} from "schemas/email";
import { SetAlertType } from "schemas/functions";
import { routes } from "schemas/routes";

import ConfirmDialog from "components/Dialogs/ConfirmDialog";
import Drawer from "components/Drawer";
import EmailThread from "components/EmailThread";
import EmailDraftComponent from "components/EmailThread/EmailDraft";
import FollowupEmailsDraft from "components/EmailThread/FollowupEmail";
import { fetcherAuth } from "utils/api";
import { openBase64DataInTab } from "utils/files";
import { navigateBackPath } from "utils/navigation";
import { isInvalidEmail } from "utils/string";
import { trackEvent } from "utils/tracking";

import styles from "./styles";

interface OutreachEmailDrawerProps {
  setAlert: SetAlertType;
}

export default function OutreachEmail({ setAlert }: OutreachEmailDrawerProps) {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const { outreachContactId: id, threadId } = useParams();
  const outreachContactId = Number(id);
  const { currentOrg, currentUser } = useContext(OrganizationUserContext);
  const { setErrorAlert } = useContext(AlertContext);
  const {
    emailHealth,
    setOpenIntegrationDialog,
    fetchIntegrationHealth,
    userIntegration,
  } = useContext(UserIntegrationsContext);
  const { discoverTab } = useContext(DiscoverViewContext);
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const fullScreenEmail = isLargeScreen && discoverTab !== null;
  const [searchParams, setSearchParams] = useSearchParams();

  const displayFollowUp =
    searchParams.get("display_followup_templates") === "true";

  const [showDraftEditor, setShowDraftEditor] = useState<boolean>(false);
  const [gmailThread, setGmailThread] = useState<GmailThread | undefined>();
  const [emailDraft, setEmailDraft] = useState<EmailDraft>({
    toContacts: [],
    ccContacts: [],
    body: "",
    subject: "",
  });
  const [emailThreadSetting, setEmailThreadSetting] =
    useState<EmailThreadSetting>({
      disableFollowUpTasks: false,
    });
  const [followupEmails, setFollowupEmails] = useState<Email[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [sendLoading, setSendLoading] = useState<boolean>(false);
  const { currentContact, fetchContact, fetchEmailThreads } = useContext(
    OutreachContactsContext,
  );
  const [missingIntegration, setMissingIntegration] = useState(false);

  const handleBack = () => {
    if (emailDraft?.isChanged) {
      setConfirmDialogOpen(true);
      return;
    }
    navigate(navigateBackPath(location.pathname, 2));
  };

  const closeDrawer = () => {
    navigate(`/${routes.inbox}`);
  };

  const fetchEmailThread = async () => {
    if (!currentOrg || currentContact?.id !== outreachContactId) {
      return;
    }
    setLoading(true);
    setGmailThread(undefined);
    setEmailThreadSetting({
      disableFollowUpTasks: false,
    });
    setFollowupEmails([]);
    if (!emailDraft?.body) {
      setEmailDraft({
        toContacts: [],
        ccContacts: [],
        body: "",
        subject: "",
      });
    }

    try {
      const { gmailThread, followupEmails, emailDraft, emailThreadSetting } =
        await fetcherAuth(
          getToken,
          `/api/organization/${currentOrg?.id}/outreach-contacts/${outreachContactId}/threads/${threadId}`,
          "GET",
        );
      setGmailThread(gmailThread);
      setEmailThreadSetting(emailThreadSetting);
      setFollowupEmails(followupEmails);
      if (emailDraft) {
        setEmailDraft(emailDraft);
        setShowDraftEditor(true);
      } else {
        setEmailDraft((prev) => ({
          ...prev,
          toContacts: [
            {
              name: currentContact.contactName,
              email: currentContact.email || "",
            },
          ],
          ccContacts: [],
          subject: gmailThread?.messages[0]?.subject
            ? `Re: ${gmailThread?.messages[0]?.subject}`
            : "",
          body: prev?.body?.length > 0 ? prev.body : "",
        }));
      }
    } catch (error) {
      if (error?.message?.includes("Google integration")) {
        setMissingIntegration(true);
      } else {
        setErrorAlert(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const openAttachment = async (
    messageId: string,
    attachment: MessageAttachment,
  ) => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/user-integrations/${userIntegration?.id}/messages/${messageId}/attachments/${attachment.attachment_id}`,
        "GET",
      );
      openBase64DataInTab(res.attachment.data, attachment.mime_type);
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const noSubject = !gmailThread?.messages[0]?.subject;

  const hasInvalidEmail = () => {
    for (const contact of emailDraft.ccContacts) {
      if (isInvalidEmail(contact.email)) {
        return true;
      }
    }
    for (const contact of emailDraft.toContacts) {
      if (isInvalidEmail(contact.email)) {
        return true;
      }
    }
    return false;
  };

  const handleSendDraftEmail = async () => {
    if (!emailHealth) {
      setOpenIntegrationDialog(true);
      return;
    }

    if (hasInvalidEmail()) {
      setAlert(
        "Some of the entered email addresses were invalid. Please make sure they are properly formatted.",
        "error",
      );
      return;
    }

    try {
      setSendLoading(true);
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts/${outreachContactId}/send-gmail-message`,
        "POST",
        {},
        {
          ...emailDraft,
          emailDraftId: emailDraft.id,
          gmailThreadId: emailDraft?.gmailThreadId || gmailThread?.id,
          disableFollowUpTasks: emailThreadSetting.disableFollowUpTasks,
        },
      );
      setAlert("Successfully sent email!", "success");
      fetchEmailThread();
      fetchEmailThreads(outreachContactId, true);
      fetchContact(outreachContactId);
      setShowDraftEditor(false);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setSendLoading(false);
    }
  };

  const reply = () => {
    if (!currentContact) {
      return;
    }
    trackEvent("Outreach Contact Thread Reply Button Clicked", {
      contactId: outreachContactId,
      "Gmail Thread ID": gmailThread?.id,
      emailDraft,
    });
    setEmailDraft({
      toContacts: [
        {
          name: currentContact.contactName,
          email: currentContact.email || "",
        },
      ],
      ccContacts: [],
      body: "",
      subject: gmailThread?.messages[0]?.subject
        ? `Re: ${gmailThread?.messages[0]?.subject}`
        : "",
    });
    setShowDraftEditor(true);
  };

  const replyAll = () => {
    if (!currentContact) {
      return;
    }

    trackEvent("Outreach Contact Thread Reply All Button Clicked", {
      contactId: outreachContactId,
      "Gmail Thread ID": gmailThread?.id,
      emailDraft,
    });
    setEmailDraft({
      toContacts: [
        {
          name: currentContact.contactName,
          email: currentContact.email || "",
        },
      ],
      ccContacts:
        gmailThread?.messages[gmailThread.messages.length - 1]?.cc || [],
      body: "",
      subject: gmailThread?.messages[0]?.subject
        ? `Re: ${gmailThread?.messages[0]?.subject}`
        : "",
    });
    setShowDraftEditor(true);
  };

  useEffect(() => {
    fetchEmailThread();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContact?.id, threadId, currentOrg?.id]);

  useEffect(() => {
    if (currentOrg?.id) {
      fetchIntegrationHealth(currentOrg?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  if (!currentContact) {
    return <></>;
  }

  const noThreadFound = (
    <Typography
      component="div"
      sx={{ mt: 2, mx: "auto", textAlign: "center" }}
      variant="caption"
      color="textSecondary"
    >
      No email thread could be found. Contact hello@onbento.com for assistance
      if this is not expected.
    </Typography>
  );

  const hasFollowUp = followupEmails?.length > 0;
  const hasTaskDraft = emailDraft.body !== null && emailDraft.body !== "";

  const getDialogContent = () => (
    <>
      {(fullScreenEmail || isSmallScreen) && (
        <DialogTitle sx={styles.dialogTitleText}>
          <Grid container alignItems="center" justifyContent={"space-between"}>
            <IconButton color="secondary" size="small" onClick={handleBack}>
              <Box component="i" className="fa-solid fa-arrow-left" />
            </IconButton>
            <Box>
              <Typography noWrap sx={styles.brandName}>
                {gmailThread?.messages[0]?.subject}
              </Typography>
            </Box>
            <Box>
              <Typography
                component="span"
                noWrap
                variant="caption"
                color="textSecondary"
              >
                {currentContact?.brand?.name}
              </Typography>
            </Box>
          </Grid>
        </DialogTitle>
      )}
      <DialogContent sx={styles.dialogContent}>
        {loading && (
          <Grid
            container
            sx={styles.progressContainer}
            gap={2}
            alignItems="center"
            justifyContent="center"
          >
            <Skeleton
              animation="wave"
              variant="rounded"
              height={40}
              width="90%"
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              height={30}
              width="90%"
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              height={400}
              width="90%"
            />
          </Grid>
        )}

        {missingIntegration && (
          <Alert severity="error">
            Log in to Google on your{" "}
            <Link style={{ color: "black" }} to={`/${routes.settings}`}>
              settings page
            </Link>{" "}
            to continue using this feature
          </Alert>
        )}

        {gmailThread && currentUser && (
          <>
            {gmailThread?.messages?.length > 0 ? (
              <EmailThread
                messages={gmailThread.messages}
                openAttachment={openAttachment}
                showSubject={!isSmallScreen}
              />
            ) : (
              <>
                {currentContact &&
                  currentContact.automatedStatus ===
                    OutreachContactAutomatedStatus.invalid &&
                  noThreadFound}
              </>
            )}

            {(!hasFollowUp || hasTaskDraft) && (
              <>
                {(showDraftEditor || displayFollowUp) && (
                  <EmailDraftComponent
                    emailDraft={emailDraft}
                    gmailThread={gmailThread}
                    setEmailDraft={setEmailDraft}
                    handleSendDraftEmail={handleSendDraftEmail}
                    from={{
                      email: userIntegration?.email || currentUser.email,
                      name: currentUser.name,
                    }}
                    cancelDraft={() => {
                      setShowDraftEditor(false);
                      searchParams.delete("display_followup_templates");
                      setSearchParams(searchParams);
                    }}
                    sendLoading={sendLoading}
                    displaySubjectEditor={
                      noSubject && gmailThread.messages?.length === 0
                    }
                    emailThreadSetting={emailThreadSetting}
                    setEmailThreadSetting={setEmailThreadSetting}
                    currentContact={currentContact}
                    displayFollowUp={displayFollowUp}
                  />
                )}
                {!(showDraftEditor || displayFollowUp) && (
                  <Grid
                    container
                    spacing={1}
                    sx={{ mt: 1 }}
                    justifyContent="center"
                  >
                    <Grid item xs="auto">
                      <Button onClick={reply} variant="outlined">
                        Reply{" "}
                        <Box
                          component="i"
                          className="fa-solid fa-reply"
                          sx={{ ml: 1 }}
                        />
                      </Button>
                    </Grid>
                    <Grid item xs="auto">
                      <Button onClick={replyAll} variant="outlined">
                        Reply All{" "}
                        <Box
                          component="i"
                          className="fa-solid fa-reply-all"
                          sx={{ ml: 1 }}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            {hasFollowUp && !hasTaskDraft && (
              <FollowupEmailsDraft
                followupEmails={followupEmails}
                setFollowupEmails={setFollowupEmails}
                setAlert={setAlert}
                setGmailThread={setGmailThread}
                gmailThread={gmailThread}
                setShowDraftEditor={setShowDraftEditor}
              />
            )}
          </>
        )}
      </DialogContent>
    </>
  );

  if (!isSmallScreen) {
    return getDialogContent();
  }

  return (
    <Drawer open={true} onClose={closeDrawer}>
      {getDialogContent()}
      <ConfirmDialog
        header={`Are you sure?`}
        subheader={
          "You have an unsaved draft. Are you sure you want to navigate away from this page?"
        }
        open={confirmDialogOpen}
        handleClose={() => setConfirmDialogOpen(false)}
        handleConfirm={() => navigate(-1)}
      />
    </Drawer>
  );
}
