import { Button, TableCell, TableRow, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ContactViewContext } from "contexts/ContactView";
import { ContactViewSearchContext } from "contexts/ContactViewSearch";
import { useContext } from "react";
import { AutomatedContactProperty, OutreachContact } from "schemas/dashboard";

import { ADD_NEW_ROW } from "features/Influencer/Tracking/events";
import {
  ContactViewProperty,
  EMAIL_COLUMN,
} from "features/Influencer/Tracking/schema";

import { getStyles } from "./styles";

interface Props {
  tbody: HTMLTableSectionElement | null;
  props: ContactViewProperty[];
}

const AddRow = ({ props, tbody }: Props) => {
  const {
    newContact,
    setNewContact,
    outreachContacts,
    selectedView,
    trackTable,
  } = useContext(ContactViewContext);
  const { contactViewParams } = useContext(ContactViewSearchContext);
  const totalFiltersSet = selectedView?.contactViewProperties?.filter(
    (x) =>
      x.filters?.length > 0 &&
      !(
        selectedView?.name !== "All" &&
        x.contactProperty?.automatedProperty === AutomatedContactProperty.stage
      ),
  )?.length;

  const styles = getStyles(outreachContacts?.length > 0);

  const focusElement = () => {
    const cellIndex = props.findIndex(
      (prop) => prop.contactPropertyName === EMAIL_COLUMN,
    );
    if (cellIndex > -1) {
      const rowNumber = (tbody?.children.length || 0) - 2;
      if (rowNumber > 1) {
        const inputField =
          tbody?.children[rowNumber]?.children[cellIndex]?.querySelector(
            "input",
          );
        inputField?.focus();
      }
    }
  };

  const handleAddNewRow = () => {
    if (selectedView?.name !== "All") {
      return;
    }
    trackTable(ADD_NEW_ROW);
    if (newContact) {
      focusElement();
      return;
    }
    setNewContact({
      email: "",
      id: -1,
      brand: { name: "", id: -1 },
    } as OutreachContact);
    setTimeout(() => {
      focusElement();
    }, 50);
  };

  const renderText = () => {
    if (
      (contactViewParams.query || (totalFiltersSet && totalFiltersSet > 0)) &&
      outreachContacts?.length === 0
    ) {
      return (
        <div>No contacts match this filter. Reset filters to see contacts.</div>
      );
    } else if (outreachContacts?.length === 0) {
      if (selectedView?.name !== "All") {
        return (
          <>You do not have any contacts in the "{selectedView?.name}" stage.</>
        );
      } else {
        return (
          <>
            You do not have any outreach contacts. To start tracking, send an
            outreach email, add a contact manually, or import contacts through
            CSV.
          </>
        );
      }
    }
  };
  return (
    <>
      {outreachContacts?.length === 0 && (
        <TableRow>
          <TableCell sx={styles.noContactCell} colSpan={props.length}>
            {renderText()}
          </TableCell>
        </TableRow>
      )}
      <TableRow
        onClick={handleAddNewRow}
        sx={{ position: "sticky", bottom: 0, zIndex: 200 }}
      >
        <TableCell sx={styles.addRowCell}>
          <Tooltip
            title={
              selectedView?.name !== "All" &&
              "You can only add new rows on the `All` tab"
            }
          >
            <span>
              <Button
                size="small"
                disabled={selectedView?.name !== "All"}
                sx={styles.newRowButton}
              >
                + New Row
              </Button>
            </span>
          </Tooltip>
        </TableCell>
        <TableCell
          colSpan={props.length - 1}
          sx={[
            styles.addRow,
            selectedView?.name === "All" && styles.addRowHover,
            { borderRight: `1px solid ${grey[300]}` },
          ]}
        ></TableCell>
      </TableRow>
    </>
  );
};

export default AddRow;
