import { useAuth } from "@clerk/clerk-react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  Divider,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import logoImg from "assets/logos/logo-transparent.png";
import { BrandsContext } from "contexts/Brands";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { TasksContext } from "contexts/Tasks";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TaskSort, TaskStatus } from "schemas/dashboard";
import { routes } from "schemas/routes";

import {
  BENTO_RESOURCES_GUIDE_LINK,
  LANDING_PAGE_LINK,
} from "constants/influencer";
import DiscoverSearchBar from "features/Influencer/Discover/Search";
import { isPage } from "utils/general";
import {
  DISPLAY_SCHEDULED_DIALOG,
  ON_BEHALF_OF_TOKEN,
  SCHEDULED_DATE_EMAIL,
} from "utils/localStorage";
import { generateInitials } from "utils/string";
import { trackEvent } from "utils/tracking";
import { usePageSize } from "utils/usePageSize";

import TopNavigationMobile from "./mobile";
import { Page, PageMenu } from "./schema";
import styles from "./styles";

function TopNavigation() {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const onBehalfToken = sessionStorage.getItem(ON_BEHALF_OF_TOKEN);

  const { signOut } = useAuth();
  const navigate = useNavigate();

  const { clearOrganizationContext, currentUser, currentOrg } = useContext(
    OrganizationUserContext,
  );
  const { discoverTab } = useContext(DiscoverViewContext);
  const { navigationWidth } = usePageSize();

  const showMobileNavigation =
    isMobileScreen || (isMediumScreen && discoverTab !== null);

  const { tasks, fetchTasks } = useContext(TasksContext);
  const pendingTasks = tasks?.filter((x) => x.status === TaskStatus.PENDING);

  const { clearBrandContext } = useContext(BrandsContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    trackEvent("Settings Menu Dropdown Clicked");
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const trackPageNavigation = (route: string) => {
    trackEvent("Page Navigated", { route });
    handleClose();
  };

  const handleLogOut = () => {
    trackEvent("Logout Button Clicked");
    if (onBehalfToken) {
      sessionStorage.removeItem(ON_BEHALF_OF_TOKEN);
      window.open("/admin", "_self");
    } else {
      signOut(() => navigate(`/sign-in`, { replace: true }));
    }

    // Reset all contexts.
    clearBrandContext();
    clearOrganizationContext();

    // Reset localStorage
    localStorage.removeItem(`${DISPLAY_SCHEDULED_DIALOG}-${currentOrg?.id}`);
    localStorage.removeItem(`${SCHEDULED_DATE_EMAIL}-${currentOrg?.id}`);
  };

  const handleOpenHowToUseBento = () => {
    trackEvent("How To Use Bento Link Clicked");
    window.open(BENTO_RESOURCES_GUIDE_LINK, "_blank");
  };

  const goToLandingPage = () => {
    trackEvent("Landing Page Logo Clicked", {
      "Clicked From": "Navigation Bar",
    });
    window.open(LANDING_PAGE_LINK, "_blank");
  };

  const renderBellIcon = () => {
    const redirectToTaskPage = () => {
      trackEvent("Tasks Notification Clicked");
      navigate(`/dashboard/tasks/all`);
    };

    return (
      <IconButton
        sx={[styles.bell, { mr: showMobileNavigation ? 0 : 2 }]}
        onClick={redirectToTaskPage}
      >
        <Badge badgeContent={pendingTasks?.length} color="primary">
          <Box component="i" className="fa-regular fa-bell" />
        </Badge>
      </IconButton>
    );
  };

  useEffect(() => {
    if (!tasks || tasks?.length === 0) {
      fetchTasks(
        TaskStatus.PENDING,
        TaskSort.DUE_DATE,
        "brand,project,outreach_contact",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, tasks?.length]);

  const renderPage = (page: string) => {
    const isActive = isPage(page);
    return (
      <Link to={`/dashboard/${page}`}>
        <Button
          sx={[styles.menuButton, isActive ? styles.activeMenuButton : {}]}
          onClick={() => trackPageNavigation(page)}
        >
          {PageMenu[page]?.label}
        </Button>
      </Link>
    );
  };

  return (
    <>
      {showMobileNavigation ? (
        <TopNavigationMobile
          width={navigationWidth}
          handleClick={handleClick}
          renderBellIcon={renderBellIcon}
        />
      ) : (
        <Grid
          sx={[
            styles.navigation,
            {
              width: navigationWidth,
            },
          ]}
          gap={1}
          justifyContent="space-between"
        >
          <Grid item xs="auto">
            <Grid container alignItems="center">
              <Box
                component="img"
                src={logoImg}
                sx={styles.topBarLogo}
                onClick={goToLandingPage}
              />
              {renderPage(Page.RECOMMENDATIONS)}
              {renderPage(Page.BRANDS)}
              {renderPage(Page.INBOX)}
              {renderPage(Page.TRACKING)}
            </Grid>
          </Grid>

          <Grid item xs>
            {isPage(routes.brands) && <DiscoverSearchBar />}
          </Grid>

          <Grid
            item
            container
            xs="auto"
            justifyContent="flex-end"
            alignItems="center"
          >
            {renderBellIcon()}
            <ButtonBase onClick={handleClick}>
              <Avatar sx={styles.avatar}>
                {generateInitials(
                  currentUser?.name || currentUser?.email || "",
                )}
              </Avatar>{" "}
              <Box
                component="i"
                className="fa-regular fa-chevron-down"
                sx={styles.arrow}
              />
            </ButtonBase>
          </Grid>
        </Grid>
      )}

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {showMobileNavigation &&
          [Page.RECOMMENDATIONS, Page.BRANDS, Page.INBOX, Page.TRACKING]?.map(
            (page) => (
              <Link
                to={`/dashboard/${page}`}
                style={{ textDecoration: "none" }}
                key={`page-${page}`}
              >
                <MenuItem key={page} onClick={() => trackPageNavigation(page)}>
                  <ListItemIcon sx={styles.icon}>
                    <Box component="i" className={PageMenu[page]?.icon} />
                  </ListItemIcon>
                  <ListItemText>{PageMenu[page]?.label}</ListItemText>
                </MenuItem>
              </Link>
            ),
          )}
        <Link
          to={`/dashboard/${Page.TEMPLATES}`}
          style={{ textDecoration: "none" }}
        >
          <MenuItem onClick={() => trackPageNavigation(Page.TEMPLATES)}>
            <ListItemIcon sx={styles.icon}>
              <Box component="i" className="fa-solid fa-file-pen" />
            </ListItemIcon>
            <ListItemText>Templates</ListItemText>
          </MenuItem>
        </Link>
        <MenuItem onClick={handleOpenHowToUseBento}>
          <ListItemIcon sx={styles.icon}>
            <Box component="i" className="fa-regular fa-wrench" />
          </ListItemIcon>
          <ListItemText>How To Use Bento</ListItemText>
        </MenuItem>
        <Link to={`/${routes.settings}`} style={{ textDecoration: "none" }}>
          <MenuItem onClick={() => trackPageNavigation(Page.SETTINGS)}>
            <ListItemIcon sx={styles.icon}>
              <Box component="i" className="fa-solid fa-gear" />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </MenuItem>
        </Link>
        <Link to={`/${routes.profile}`} style={{ textDecoration: "none" }}>
          <MenuItem onClick={() => trackPageNavigation(Page.PROFILE)}>
            <ListItemIcon sx={styles.icon}>
              <Box component="i" className="fa-solid fa-user" />
            </ListItemIcon>
            <ListItemText>Profile</ListItemText>
          </MenuItem>
        </Link>
        <Divider />
        <MenuItem onClick={handleLogOut}>
          <ListItemIcon sx={styles.icon}>
            <Box component="i" className="fa-solid fa-sign-out" />
          </ListItemIcon>
          <ListItemText>Log Out</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export default TopNavigation;
