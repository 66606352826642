import { Box, Grid, IconButton } from "@mui/material";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { useContext } from "react";
import { SavedBrand, SavedBrandCollection } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";
import { HEART_COLOR } from "styles/oneOffColor";

import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import styles from "../styles";

interface Props {
  collection: SavedBrandCollection;
  setSelectedCollection: () => void;
  getTotal: (collectionId: number) => JSX.Element;
}

const Collection = ({ collection, setSelectedCollection, getTotal }: Props) => {
  const collectionId = collection.id;
  const collectionName = collection.name;

  const { setCollectionsMap } = useContext(SavedBrandCollectionsContext);
  const { brandBeingSaved, handleMoveDebounced } =
    useContext(SavedBrandContext);

  const handleMoveToCollection = (e: CustomEvent) => {
    if (!brandBeingSaved) return;
    e.stopPropagation();

    trackEvent("Favorites List - Quick Move Saved Brand Button Clicked", {
      "Collection ID": collectionId,
    });

    const oldCollectionId =
      brandBeingSaved.bentoBrand.savedBrandCollectionId || 0;

    handleMoveDebounced(
      brandBeingSaved.bentoBrand,
      oldCollectionId,
      collectionId,
    );

    setCollectionsMap((prev) => {
      const copy = makeDeepCopy(prev);
      const index = copy[0]?.findIndex(
        (sb: SavedBrand) =>
          Number(sb.bentoBrandId) === Number(brandBeingSaved.bentoBrandId),
      );
      if (index > -1) {
        copy[0][index]["bentoBrand"]["savedBrandCollectionId"] = collectionId;
      }
      return copy;
    });
  };

  const getActionText = () => {
    if (brandBeingSaved && collectionId === 0) {
      return (
        <Grid sx={{ px: 2 }}>
          Saved{" "}
          <Box
            component="i"
            className="fa-solid fa-bookmark"
            sx={{ color: HEART_COLOR, ml: 1 }}
          />
        </Grid>
      );
    } else if (brandBeingSaved) {
      return (
        <IconButton sx={styles.circlePlus} onClick={handleMoveToCollection}>
          <Box
            component="i"
            className="fa-regular fa-circle-plus"
            sx={styles.chevron}
          />
        </IconButton>
      );
    } else {
      return (
        <IconButton sx={styles.circlePlus}>
          <Box
            sx={styles.chevron}
            component="i"
            className="fa-regular fa-arrow-right"
          />
        </IconButton>
      );
    }
  };

  return (
    <Grid key={`${collectionId}-${collectionName}`}>
      <Grid
        container
        alignItems="center"
        sx={styles.collection}
        justifyContent="space-between"
        onClick={setSelectedCollection}
      >
        <Box>
          {collectionName}
          {getTotal(collectionId)}
        </Box>
        {getActionText()}
      </Grid>
    </Grid>
  );
};

export default Collection;
