import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import { QuickSendDrawerContext } from "contexts/QuickSendDrawer";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { forwardRef, useContext } from "react";
import { BentoBrand } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import EmailButton from "components/EmailButton";
import { generateInitials } from "utils/string";
import { makeDeepCopy } from "utils/updateLocalState";
import { useCollection } from "utils/useCollection";

import styles from "./styles";

interface SelectedBrandProps {
  bentoBrand: BentoBrand;
  collectionId?: number;
  isBulkMode?: boolean;
  index: number;
}

const SelectedBrand = forwardRef(
  (
    { bentoBrand, collectionId = -1, isBulkMode, index }: SelectedBrandProps,
    ref,
  ) => {
    const { isDraftBrand } = useCollection();
    const { handleRemoveDebounced, removeLoading, setBulkBrands } =
      useContext(SavedBrandContext);
    const { handleOpenQuickSendIndividual, quickSendBrandId } = useContext(
      QuickSendDrawerContext,
    );
    const { collectionsNames } = useContext(SavedBrandCollectionsContext);

    const handleDelete = (e: CustomEvent) => {
      e.stopPropagation();
      handleRemoveDebounced(bentoBrand, collectionId);
    };

    const handleSelect = () => {
      setBulkBrands((prev) => {
        const copy = makeDeepCopy(prev);
        const index = prev.findIndex(
          (b) => Number(b.id) === Number(bentoBrand.id),
        );
        if (index > -1) {
          copy.splice(index, 1);
        } else {
          copy.push(bentoBrand);
        }
        return copy;
      });
    };

    const clickOnBrand = () => {
      handleOpenQuickSendIndividual(
        bentoBrand?.id,
        "Favorites List",
        undefined,
        true,
      );
    };

    const goToInstagram = (e: CustomEvent) => {
      e.stopPropagation();
      window.open(
        `https://instagram.com/${bentoBrand.instagramUsername}`,
        "_blank",
      );
    };

    const isSelected = quickSendBrandId === bentoBrand?.id;

    const renderLeftButton = () => {
      if (isBulkMode) {
        return (
          <Box onClick={(e) => e.stopPropagation()}>
            <Checkbox
              onChange={handleSelect}
              disableRipple
              icon={
                <Box
                  sx={{ color: "green.main" }}
                  component="i"
                  className="fa-regular fa-square"
                />
              }
              checkedIcon={
                <Box
                  component="i"
                  className="fa-solid fa-square-check"
                  sx={{ color: "green.main" }}
                />
              }
            />
          </Box>
        );
      } else {
        return (
          <>
            {removeLoading === bentoBrand?.id ? (
              <CircularProgress size={20} />
            ) : (
              <IconButton onClick={handleDelete} sx={styles.removeButton}>
                <Box component="i" className="fa-regular fa-circle-xmark" />
              </IconButton>
            )}
          </>
        );
      }
    };

    return (
      <Box
        ref={ref}
        sx={[styles.brandContainer, isSelected && styles.selected]}
        onClick={clickOnBrand}
        key={`cart-brand-${bentoBrand.id}`}
      >
        {renderLeftButton()}
        <Box sx={styles.brandImage}>
          {bentoBrand.logoUrl ? (
            <img
              src={bentoBrand.logoUrl}
              alt={`${bentoBrand.brandName} logo`}
            />
          ) : (
            <Avatar sx={styles.avatar}>
              {generateInitials(bentoBrand.brandName)}
            </Avatar>
          )}
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={styles.brandName}>
            {bentoBrand.brandName}
            {(isDraftBrand(bentoBrand) || bentoBrand?.hasOutreachDraft) && (
              <>
                ,{" "}
                <Box component="span" sx={styles.draft}>
                  Draft{" "}
                </Box>
              </>
            )}
          </Box>

          {bentoBrand.instagramUsername && (
            <Link target="_blank" onClick={goToInstagram} sx={{ fontSize: 12 }}>
              @{bentoBrand.instagramUsername}
            </Link>
          )}

          {bentoBrand?.savedBrandCollectionId && collectionId === 0 ? (
            <Typography variant="body2" sx={{ mt: 1 }}>
              In{" "}
              <strong>
                <em>{collectionsNames[bentoBrand?.savedBrandCollectionId]}</em>
              </strong>
            </Typography>
          ) : (
            <></>
          )}
        </Box>

        <EmailButton />
      </Box>
    );
  },
);

export default SelectedBrand;
