import { useAuth } from "@clerk/clerk-react";
import { Box, IconButton } from "@mui/material";
import { grey, yellow } from "@mui/material/colors";
import { AlertContext } from "contexts/Alert";
import { ContactViewContext } from "contexts/ContactView";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext } from "react";
import { OutreachContact } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { fetcherAuth } from "utils/api";

interface IProps {
  contact: OutreachContact;
}

const styles = {
  icon: {
    fontSize: 13,
    color: grey[500],
  },
  pinned: {
    color: yellow[700],
  },
};

export default function PinnedIcon({ contact }: IProps) {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setAlert } = useContext(AlertContext);
  const { setOutreachContacts } = useContext(ContactViewContext);
  const { getToken } = useAuth();

  const handleClick = (e: CustomEvent) => {
    e.stopPropagation();
    handleChangeStatus(!contact.isPinned);
    setOutreachContacts((prev) => {
      const copy = [...prev];
      let lastPinnedIndex = -1;
      let contactIndex = -1;
      for (let i = 0; i < prev.length; i++) {
        if (copy[i].isPinned) {
          lastPinnedIndex = i;
        }
        if (copy[i].id === contact.id) {
          contactIndex = i;
        }
      }
      copy[contactIndex].isPinned = !copy[contactIndex].isPinned;

      if (contactIndex === lastPinnedIndex) {
        return copy;
      } else if (contactIndex < lastPinnedIndex) {
        const [item] = copy.splice(contactIndex, 1);
        copy.splice(lastPinnedIndex, 0, item);
      } else {
        const [item] = copy.splice(contactIndex, 1);
        copy.splice(lastPinnedIndex + 1, 0, item);
      }
      return copy;
    });
  };

  const handleChangeStatus = async (newPinnedValue: boolean) => {
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/outreach-contacts/${contact?.id}`,
        "PATCH",
        {},
        {
          isPinned: newPinnedValue,
        },
      );
    } catch (error) {
      setAlert(
        "Unable to update status. Please reload and retry again",
        "error",
      );
    }
  };

  return (
    <IconButton
      size="small"
      sx={[styles.icon, !!contact.isPinned && styles.pinned]}
      onClick={handleClick}
    >
      <Box
        component="i"
        className={contact.isPinned ? "fa-solid fa-star" : "fa-regular fa-star"}
      />
    </IconButton>
  );
}
