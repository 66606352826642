import { useAuth } from "@clerk/clerk-react";
import { createContext, useContext, useEffect, useState } from "react";
import {
  BentoBrand,
  BentoContact,
  NewBentoContact,
  OutreachDraft,
  PreviewMessage,
} from "schemas/dashboard";

import { Template } from "features/Influencer/ContactList/schema";
import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";
import { useCollection } from "utils/useCollection";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";
import { OutreachTemplatesContext } from "./OutreachTemplates";
import { QuickSendContext } from "./QuickSend";
import { QuickSendContactsContext } from "./QuickSendContacts";
import { QuickSendDrawerContext } from "./QuickSendDrawer";
import {
  draftToPreview,
  getDraftTemplates,
  previewToDraft,
} from "./helpers/QuickSendHelpers";

export type DraftRef = {
  bentoBrand: BentoBrand;
  emails: PreviewMessage[];
  previewContact: BentoContact | NewBentoContact;
  isAutogenerated?: boolean;
};
interface QuickSendEmailDraftsContextInterface {
  createEmailDraft: (sanitizedData: DraftRef) => void;
  fetchDraftLoading: boolean;
  outreachDraft: OutreachDraft | null;
  fetchOutreachDraft: (bentoBrandId: number) => void;
  draftTemplates: Template[];
  createDraftLoading: number;
  clearDraftTemplates: () => void;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  createEmailDraft: defaultContextMissingFunction,
  fetchDraftLoading: true,
  outreachDraft: null,
  fetchOutreachDraft: defaultContextMissingFunction,
  draftTemplates: [],
  createDraftLoading: -1,
  clearDraftTemplates: defaultContextMissingFunction,
};

const QuickSendEmailDraftsContext =
  createContext<QuickSendEmailDraftsContextInterface>(defaultInterface);

interface QuickSendEmailDraftsProviderProps {
  children: React.ReactNode;
}

const QuickSendEmailDraftsProvider = ({
  children,
}: QuickSendEmailDraftsProviderProps) => {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const { setAlert } = useContext(AlertContext);
  const {
    setOutreachMessages,
    bentoBrand,
    setBentoContact,
    setTemplateForQuickSend,
    setNewlyAddedTemplateIndex,
    previewLoading,
  } = useContext(QuickSendContext);
  const { addDraftToCollection } = useCollection();

  const { updateLastContacted, bentoContacts, setBentoContacts } = useContext(
    QuickSendContactsContext,
  );
  const { quickSendBrandId, draftIsSet, setDraftIsSet } = useContext(
    QuickSendDrawerContext,
  );
  const { templates, setTemplates, setCreateTemplateDialogOpen } = useContext(
    OutreachTemplatesContext,
  );
  const [draftTemplates, setDraftTemplates] = useState<Template[]>([]);
  const [createDraftLoading, setCreateDraftLoading] = useState(-1);
  const [fetchDraftLoading, setFetchDraftLoading] = useState(true);
  const [outreachDraft, setOutreachDraft] = useState<OutreachDraft | null>(
    null,
  );
  const [openedCreateTemplateDialog, setOpenedCreateTemplateDialog] =
    useState<boolean>(false);

  const bentoBrandId = Number(bentoBrand?.id);

  const outreachIsFetched =
    outreachDraft &&
    bentoBrandId === Number(outreachDraft?.bentoBrandId) &&
    previewLoading === -1;
  const noOutreachDraftFound = !fetchDraftLoading && Number(bentoBrandId) > -1;

  const createEmailDraft = async (sanitizedData: DraftRef) => {
    const { emails, previewContact, bentoBrand } = sanitizedData;
    const bentoBrandId = Number(bentoBrand?.id);
    setCreateDraftLoading(bentoBrandId);
    addDraftToCollection(sanitizedData.bentoBrand);

    const emailDrafts = emails.map((email) => previewToDraft(email));
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-brands/${bentoBrand?.id}/outreach-draft`,
        "POST",
        {},
        {
          previewContact,
          emailDrafts,
        },
      );
      setAlert(`Autosaved your outreach for ${bentoBrand?.brandName}`, "info");
    } catch (error) {
      setAlert(
        "Unable to save email drafts. Please reload and retry again",
        "error",
      );
    } finally {
      setCreateDraftLoading(-1);
    }
  };

  const fetchOutreachDraft = async (bentoBrandId: number) => {
    setFetchDraftLoading(true);
    setTemplates((prev) => {
      let copy = makeDeepCopy(prev);
      // Remove all draft templates before replacing them with new one.
      copy = copy?.filter((x: Template) => x.id && !isNaN(x.id));
      return copy;
    });
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/bento-brands/${bentoBrandId}/outreach-draft`,
        "GET",
      );
      if (res.outreachDraft) {
        setOutreachDraft(res.outreachDraft);
      } else {
        setDraftIsSet(bentoBrandId);
        setFetchDraftLoading(false);
      }
    } catch (error) {
      // Do not do anything. Just default back to their usual templates.
    }
  };

  const getContactName = () => {
    let name: string = "";
    if (outreachDraft?.bentoContactId) {
      const contact = bentoContacts?.find(
        (contact) => contact.id === outreachDraft?.bentoContactId,
      );
      name = contact?.name || "";
    } else {
      name = outreachDraft?.contactName || "";
    }
    const firstName = name ? name.split(" ")?.[0] : "";
    return firstName || `${bentoBrand?.brandName} Team`;
  };

  const getContactFromDraft = (outreachDraft: OutreachDraft) => {
    if (outreachDraft?.bentoContactId) {
      const contact = bentoContacts?.find(
        (contact) => contact.id === outreachDraft?.bentoContactId,
      );
      return contact;
    } else {
      // Add a new contact for user if they are creating a new draft with a new contact
      setBentoContacts((prev) => {
        const existingContact = prev?.find(
          (x) => x.email === outreachDraft?.contactEmail,
        );
        if (existingContact) setBentoContact(existingContact);
        if (!existingContact && outreachDraft?.contactEmail) {
          updateLastContacted([outreachDraft?.contactEmail]);
          const newContact = {
            email: outreachDraft?.contactEmail,
            bentoBrandId: outreachDraft?.bentoBrandId,
            name: outreachDraft?.contactName,
            title: outreachDraft?.contactTitle,
          };
          setBentoContact(newContact);
          return [...prev, newContact];
        }
        return prev;
      });
    }
    return null;
  };

  const replacePreviewWithDraft = (draftTemplates: Template[]) => {
    if (
      !outreachDraft ||
      draftTemplates?.length === 0 ||
      draftIsSet === bentoBrand?.id
    ) {
      return;
    }

    const draftBentoContact = getContactFromDraft(outreachDraft);
    if (draftBentoContact) setBentoContact(draftBentoContact);

    const to = outreachDraft?.contactEmail || draftBentoContact?.email;
    if (to) {
      const newOutreachMessages = outreachDraft?.emailDrafts.map(
        (draft, index) => draftToPreview(draft, to, draftTemplates[index]),
      );
      if (newOutreachMessages) {
        setOutreachMessages((prev) => {
          let copy = makeDeepCopy(prev);
          copy = newOutreachMessages;
          return copy;
        });
      }
    }
    setFetchDraftLoading(false);
    setDraftIsSet(outreachDraft?.bentoBrandId);
  };

  const handleOpenCreateTemplateDialog = () => {
    if (
      (!templates || templates?.length === 0) &&
      !openedCreateTemplateDialog &&
      quickSendBrandId > -1
    ) {
      trackEvent("Template Creation Dialog Opened");
      setCreateTemplateDialogOpen(true);
      setOpenedCreateTemplateDialog(true);
      setNewlyAddedTemplateIndex(0);
    }
  };

  const addDraftTemplates = async () => {
    if (!outreachDraft || draftIsSet === bentoBrand?.id) {
      return;
    }
    const contactName = getContactName();

    const draftTemplates = getDraftTemplates(
      outreachDraft,
      templates,
      contactName || "",
    );
    setDraftTemplates(draftTemplates);
    setTemplateForQuickSend(draftTemplates);
    setTemplates((prev) => {
      const copy = makeDeepCopy(prev);
      const names = copy?.map((x: Template) => x.name);
      for (const template of draftTemplates) {
        if (!names?.includes(template?.name)) {
          copy.push(template);
          names.push(template.name);
        }
      }
      return copy;
    });
    replacePreviewWithDraft(draftTemplates);
  };

  const clearDraftTemplates = () => {
    // When user exited the QuickSend drawer, remove all the draft templates.
    setTemplates((prev) => {
      return prev?.filter((x) => x.id && !isNaN(x.id));
    });
  };

  useEffect(() => {
    if (outreachIsFetched) {
      addDraftTemplates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outreachIsFetched]);

  useEffect(() => {
    if (noOutreachDraftFound) {
      handleOpenCreateTemplateDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOutreachDraftFound]);

  return (
    <QuickSendEmailDraftsContext.Provider
      value={{
        createEmailDraft,
        fetchDraftLoading,
        outreachDraft,
        fetchOutreachDraft,
        draftTemplates,
        createDraftLoading,
        clearDraftTemplates,
      }}
    >
      {children}
    </QuickSendEmailDraftsContext.Provider>
  );
};

export { QuickSendEmailDraftsProvider, QuickSendEmailDraftsContext };
