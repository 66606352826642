import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { grey } from "@mui/material/colors";
import { AlertContext } from "contexts/Alert";
import { SavedBrandContext } from "contexts/SavedBrand";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { SavedBrandCollection } from "schemas/dashboard";

import { trackEvent } from "utils/tracking";

interface Props {
  selectedCollection: SavedBrandCollection | null;
  handleCreateNewList: (bulkMove?: boolean) => void;
  setIsBulkMode: Dispatch<SetStateAction<boolean>>;
}

const styles = {
  moveTo: {
    px: 2,
    py: 1,
    borderTop: `1px solid ${grey[200]}`,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
};

export default function MoveMenu({
  selectedCollection,
  handleCreateNewList,
  setIsBulkMode,
}: Props) {
  const { setAlert } = useContext(AlertContext);
  const { bulkBrands, setBulkBrands, bulkMoveTo, bulkDelete, moveLoading } =
    useContext(SavedBrandContext);
  const { collections } = useContext(SavedBrandCollectionsContext);

  const [deleteLoading, setDeleteLoading] = useState(false);

  // Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const collectionsToMoveTo = collections.filter(
    (x) => x.id !== selectedCollection?.id,
  );

  const handleBulkMove = async (collection: SavedBrandCollection) => {
    if (bulkBrands?.length === 0) {
      setAlert("Please select at least one brand", "warning");
      return;
    }
    trackEvent("Favorites List - Bulk Move To Button Pressed", {
      "Collection ID": collection.id,
    });
    handleClose();
    await bulkMoveTo(collection.id);
    _reset();
  };

  const handleBulkDelete = async () => {
    if (bulkBrands?.length === 0) {
      setAlert("Please select at least one brand", "warning");
      return;
    }
    trackEvent("Favorites List - Bulk Delete Button Pressed", {
      "Collection ID": selectedCollection?.id || 0,
    });
    setDeleteLoading(true);
    await bulkDelete();
    setDeleteLoading(false);
    _reset();
  };

  const _reset = () => {
    setBulkBrands([]);
    setIsBulkMode(false);
  };

  const createAndMove = () => {
    handleCreateNewList(true);
  };

  return (
    <Box sx={styles.moveTo}>
      <Tooltip title="Delete Selected Brands">
        {deleteLoading ? (
          <CircularProgress size={20} />
        ) : (
          <IconButton sx={{ fontSize: 13 }} onClick={handleBulkDelete}>
            <Box component="i" className="fa-regular fa-trash" />
          </IconButton>
        )}
      </Tooltip>
      <Tooltip title="Move Selected Brands To New Collection">
        <LoadingButton size="small" onClick={handleClick} loading={moveLoading}>
          Move To
        </LoadingButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {collectionsToMoveTo?.map((collection) => (
          <MenuItem
            key={collection.id}
            onClick={() => handleBulkMove(collection)}
          >
            {collection.name}
          </MenuItem>
        ))}
        <Divider />
        <MenuItem onClick={createAndMove}>New List +</MenuItem>
      </Menu>
    </Box>
  );
}
