import {
  Box,
  Button,
  Grid,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";

import { VIRTUAL_ASSISTANT_LINK } from "utils/influencer";
import { trackEvent } from "utils/tracking";

import styles from "./styles";

export default function VirtualAssistant() {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    trackEvent("Virtual Assistant Button Hovered");
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const navigateToForm = async () => {
    trackEvent("Virtual Assistant Button Clicked");
    window.open(VIRTUAL_ASSISTANT_LINK, "_blank");
  };

  return (
    <>
      <Button
        size="small"
        variant="contained"
        sx={styles.button}
        disableElevation
        onClick={navigateToForm}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Box component="i" className="fa-thin fa-hand-wave" sx={{ mr: 1 }} />
        {isMobileScreen ? "Assistant" : "Virtual Assistant"}
      </Button>

      <Popover
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Grid container justifyContent="center" sx={{ p: 2, width: 400 }}>
          <Typography textAlign="center" gutterBottom>
            <strong>Want someone to do your outreach for you?</strong>
          </Typography>

          <Typography textAlign="center">
            Fill out the intake form to get matched with someone to send up to{" "}
            <strong>250 emails</strong> a week for you for less than $2/day.
          </Typography>
        </Grid>
      </Popover>
    </>
  );
}
