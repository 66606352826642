import { Box, Button, Popover, Typography } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { TrackingColumnsContext } from "contexts/TrackingColumns";
import { TrackingColumnsFiltersContext } from "contexts/TrackingColumnsFilters";
import { useContext, useEffect, useRef, useState } from "react";

import { ADD_FILTER_BUTTON_CLICKED } from "features/Influencer/Tracking/events";
import {
  BuiltInContactPropertyNames,
  ContactViewProperty,
  CustomFilterColumns,
  FilterType,
} from "features/Influencer/Tracking/schema";
import { makeDeepCopy } from "utils/updateLocalState";

import SingleFilter from "./SingleFilter";

export default function TableFilterPopupMenu() {
  const parentRef = useRef();
  const { trackTable, selectedView } = useContext(ContactViewContext);
  const { handleUpdateColumn } = useContext(TrackingColumnsContext);
  const {
    handleCloseFilterMenu,
    open,
    anchorEl,
    filterableColumns,
    highlightColumn,
  } = useContext(TrackingColumnsFiltersContext);
  const [selectedFilters, setSelectedFilters] = useState<ContactViewProperty[]>(
    [],
  );

  const handleAddFilter = () => {
    trackTable(ADD_FILTER_BUTTON_CLICKED);
    const picked = selectedFilters?.map((x) => x.id);
    const chooseColumn = filterableColumns?.find((x) => !picked.includes(x.id));
    const copy = makeDeepCopy(selectedFilters);
    copy.push(chooseColumn);
    setSelectedFilters(copy);
  };

  const handleChooseColumn = (prop: ContactViewProperty, index: number) => {
    const copy = makeDeepCopy(selectedFilters);
    // Remove previous filter
    if (copy[index]?.filters?.length > 0) {
      handleUpdateColumn(
        copy[index],
        {
          filter: undefined,
          sortDirection: copy[index].sortDirection,
        },
        true,
      );
    }
    copy[index] = prop;
    setSelectedFilters(copy);
  };

  const handleChooseFilter = (
    values: string[],
    index: number,
    removeFromUi?: boolean,
  ) => {
    const copy = makeDeepCopy(selectedFilters);
    const prop: ContactViewProperty = copy[index];
    if (!prop.id) return;
    const filter = {
      type:
        prop.contactPropertyName &&
        CustomFilterColumns.includes(
          prop.contactPropertyName as BuiltInContactPropertyNames,
        )
          ? FilterType.customBuiltIn
          : FilterType.selection,
      values,
    };
    if (values?.length > 0) {
      prop.filters = [filter];
      handleUpdateColumn(
        prop,
        { filter, sortDirection: prop.sortDirection },
        true,
      );
    } else {
      if (removeFromUi) {
        copy.splice(index, 1);
      } else {
        prop.filters = [];
      }
      handleUpdateColumn(
        prop,
        { filter: undefined, sortDirection: prop.sortDirection },
        true,
      );
    }
    setSelectedFilters(copy);
  };

  useEffect(() => {
    const filters = [];
    if (filterableColumns && filterableColumns?.length > 0) {
      for (const col of filterableColumns) {
        if (col.filters?.length > 0 && col.id) {
          filters.push(col);
        }
      }
    }
    setSelectedFilters(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedView?.id]);

  useEffect(() => {
    if (highlightColumn?.id) {
      const isFilterable = filterableColumns?.find(
        (x) => x.id === highlightColumn.id,
      );
      if (isFilterable) {
        const picked = selectedFilters?.map((x) => x.id);
        const alreadyPicked = picked?.includes(highlightColumn?.id);
        if (!alreadyPicked) {
          const copy = makeDeepCopy(selectedFilters);
          copy.push(highlightColumn);
          setSelectedFilters(copy);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightColumn?.id]);

  return (
    <Box ref={parentRef}>
      <Popover
        id={"filter-popup-menu"}
        open={open}
        container={parentRef.current} // used so that the loading backdrop appears above this popover
        anchorEl={anchorEl}
        onClose={handleCloseFilterMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 3 }}>
          {selectedFilters?.length > 0 && (
            <Typography sx={{ mb: 1 }}>
              In this view, filter records by{" "}
            </Typography>
          )}
          {selectedFilters?.length === 0 &&
            filterableColumns &&
            filterableColumns.length > 0 && (
              <Typography>
                You do not have any filters on this table. To add a filter, you
                can click on <strong>Add Filter +</strong> below
              </Typography>
            )}
          {selectedFilters?.length === 0 &&
            (!filterableColumns || filterableColumns.length === 0) && (
              <Typography>
                No visible fields on this table can be filtered. Change the{" "}
                <strong>Hidden Columns</strong> to show a filterable column and
                then click on the <strong>Add Filter +</strong> below
              </Typography>
            )}

          {selectedFilters?.map(
            (filter: ContactViewProperty, index: number) => (
              <SingleFilter
                prop={filter}
                handleChooseColumn={handleChooseColumn}
                index={index}
                handleChooseFilter={handleChooseFilter}
                selectedFilters={selectedFilters}
                key={index}
              />
            ),
          )}

          <Button
            size="small"
            sx={{ mt: 2, textTransform: "none" }}
            variant="outlined"
            disabled={
              !filterableColumns ||
              filterableColumns.length === 0 ||
              (selectedFilters &&
                selectedFilters.length === filterableColumns.length)
            }
            onClick={handleAddFilter}
          >
            Add Filter +{" "}
          </Button>
        </Box>
      </Popover>
    </Box>
  );
}
