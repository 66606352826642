import { useAuth } from "@clerk/clerk-react";
import { capitalize } from "lodash";
import React, {
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Subscription } from "schemas/dashboard";
import { Plans } from "schemas/payments";

import { fetcherAuth } from "utils/api";

import { OrganizationUserContext } from "./Organization";

export type AllPlans = {
  [key in Plans]: {
    monthly_limit: number;
    price: number;
    chat_monthly_limit: number;
    brand_requests_monthly_limit: number;
  };
};

interface SubscriptionContextInterface {
  subscription: Subscription | null;
  setSubscription: React.Dispatch<SetStateAction<Subscription | null>>;
  canSendEmail: () => boolean;
  canMakeBrandRequest: () => boolean;
  clearSubscriptionContext: () => void;
  plans: AllPlans | null;
  fetchPlans: () => void;
  decreaseEmailCount: () => void;
  decreaseBrandRequestsCount: () => void;
  isProPlan: boolean;
  isFreePlan: boolean;
  isStarterPlan: boolean;
  referralCode: string | null;
  setReferralCode: React.Dispatch<SetStateAction<string | null>>;
  setUpgradeDialogSource: React.Dispatch<SetStateAction<string>>;
  upgradeDialogSource: string;
  emailSents: number;
  brandRequestsMade: number;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  subscription: null,
  setSubscription: defaultContextMissingFunction,
  updateSubscription: defaultContextMissingFunction,
  canSendEmail: defaultContextMissingFunction,
  canMakeBrandRequest: defaultContextMissingFunction,
  clearSubscriptionContext: defaultContextMissingFunction,
  plans: null,
  fetchPlans: defaultContextMissingFunction,
  decreaseEmailCount: defaultContextMissingFunction,
  decreaseBrandRequestsCount: defaultContextMissingFunction,
  isProPlan: false,
  isFreePlan: true,
  isStarterPlan: false,
  referralCode: null,
  setReferralCode: defaultContextMissingFunction,
  upgradeDialogSource: "",
  setUpgradeDialogSource: defaultContextMissingFunction,
  emailSents: 0,
  brandRequestsMade: 0,
};

const SubscriptionContext =
  createContext<SubscriptionContextInterface>(defaultInterface);

interface SubscriptionProviderProps {
  children: React.ReactNode;
}

const SubscriptionProvider = ({ children }: SubscriptionProviderProps) => {
  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [upgradeDialogSource, setUpgradeDialogSource] = useState<string>("");
  const [plans, setPlans] = useState<any>({});
  const [referralCode, setReferralCode] = useState<string | null>(null);

  const clearSubscriptionContext = () => {
    setSubscription(null);
  };

  const canSendEmail = () => {
    const hasRemainingMessages =
      subscription?.remainingMessages && subscription?.remainingMessages > 0;
    return Boolean(hasRemainingMessages);
  };

  const canMakeBrandRequest = () => {
    return Boolean(subscription && subscription.remainingBrandRequests > 0);
  };

  const fetchPlans = async () => {
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/payments/subscription-plans`,
      );
      setPlans(res);
    } catch (error) {}
  };

  const fetchReferralCode = async () => {
    try {
      const { referralCode } = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/referrals/code`,
      );
      setReferralCode(referralCode);
    } catch (error) {}
  };

  const decreaseBrandRequestsCount = () => {
    setSubscription((s) => {
      if (s) {
        return {
          ...s,
          remainingBrandRequests: s.remainingBrandRequests - 1,
        };
      } else {
        return s;
      }
    });
  };

  const decreaseEmailCount = () => {
    setSubscription((s) => {
      if (s) {
        return {
          ...s,
          remainingMessages: s.remainingMessages - 1,
        };
      } else {
        return s;
      }
    });
  };

  const isProPlan = useMemo(() => {
    return subscription?.subscription?.planName === capitalize(Plans.PRO);
  }, [subscription]);

  const isFreePlan = useMemo(() => {
    return subscription?.subscription?.planName === capitalize(Plans.FREE);
  }, [subscription]);

  const isStarterPlan = useMemo(() => {
    return subscription?.subscription?.planName === capitalize(Plans.STARTER);
  }, [subscription]);

  const emailSents = subscription?.monthlyLimit
    ? subscription?.monthlyLimit - subscription?.remainingMessages
    : 0;
  const brandRequestsMade = subscription?.brandRequestsMonthlyLimit
    ? subscription?.brandRequestsMonthlyLimit -
      subscription?.remainingBrandRequests
    : 0;

  useEffect(() => {
    if (currentOrg?.id) {
      fetchPlans();
      fetchReferralCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        setSubscription,
        upgradeDialogSource,
        setUpgradeDialogSource,
        canSendEmail,
        canMakeBrandRequest,
        clearSubscriptionContext,
        plans,
        fetchPlans,
        decreaseEmailCount,
        decreaseBrandRequestsCount,
        isProPlan,
        isFreePlan,
        isStarterPlan,
        referralCode,
        setReferralCode,
        emailSents,
        brandRequestsMade,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export { SubscriptionProvider, SubscriptionContext };
