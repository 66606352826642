import { Chip, Grid } from "@mui/material";
import { BrandsContext, TagLabel } from "contexts/Brands";
import lodash from "lodash";
import { useContext } from "react";
import {
  BentoBrandMetadataTags,
  MetadataType,
  SearchParams,
} from "schemas/dashboard";
import { ALL } from "schemas/forms";

import { DiscoverSearchChipColor } from "utils/color";
import { useSearchBrand } from "utils/useSearchBrand";

import styles from "../styles";

interface Props {
  selectedTags: TagLabel[];
  isMobileScreen: boolean;
}

const DiscoverChips = ({ selectedTags, isMobileScreen }: Props) => {
  const { deleteCategoryFromSearch, deleteMetadataFromSearch, hasTags } =
    useSearchBrand();

  const { selectedMetadataTags } = useContext(BrandsContext);

  return hasTags ? (
    <Grid
      container
      wrap="nowrap"
      item
      xs={12}
      md
      gap={1}
      sx={styles.chipsContainer}
    >
      {selectedTags?.map(
        (chip: TagLabel, index: number) =>
          chip?.name &&
          chip?.params !== SearchParams.SORT_DISCOVER &&
          chip?.key !== ALL && (
            <Chip
              key={index}
              label={chip.name}
              onDelete={() => deleteCategoryFromSearch(chip)}
              sx={[
                styles.selectedChip,
                { backgroundColor: DiscoverSearchChipColor[chip.params] },
              ]}
              size={isMobileScreen ? "small" : "medium"}
            />
          ),
      )}
      {selectedMetadataTags?.map(
        (tag: BentoBrandMetadataTags, index: number) => (
          <Chip
            key={index}
            label={lodash.startCase(tag.value)}
            onDelete={() => deleteMetadataFromSearch(tag)}
            sx={[
              styles.selectedChip,
              {
                backgroundColor:
                  tag.type === MetadataType.location
                    ? DiscoverSearchChipColor[SearchParams.LOCATION_DISCOVER]
                    : DiscoverSearchChipColor[SearchParams.CATEGORY_DISCOVER],
              },
            ]}
            size={isMobileScreen ? "small" : "medium"}
          />
        ),
      )}
    </Grid>
  ) : (
    <></>
  );
};

export default DiscoverChips;
