import { Backdrop, CircularProgress } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { ContactViewSearchContext } from "contexts/ContactViewSearch";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

import OutreachContactDrawer from "features/Influencer/ContactList/OutreachContactDrawer";
import EditTemplateDialog from "features/Influencer/Templates/TemplatesTable/EditTemplate";
import ImportDialog from "features/Influencer/Tracking/ImportDialog";
import { navigateBackPath } from "utils/navigation";
import { trackEvent } from "utils/tracking";

import DraggableTable from "./DraggableTable";

const ContactView = () => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { selectedView } = useContext(ContactViewContext);
  const { viewLoading, handleSearch } = useContext(ContactViewSearchContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { contactViewId } = useParams();

  useEffect(() => {
    trackEvent("Tracking Tab Viewed", {
      viewName: selectedView?.name,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleSearch({ query: "", page: 1 }, Number(contactViewId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactViewId, currentOrg?.id]);

  const onTemplateSuccess = () => {
    navigate(navigateBackPath(location.pathname, 2), { replace: true });
  };

  return (
    <>
      {viewLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={viewLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
      )}

      {selectedView?.name && <DraggableTable />}
      <Routes>
        <Route
          path=":outreachContactId/*"
          element={<OutreachContactDrawer />}
        />
        <Route
          path=":outreachContactId/*"
          element={<OutreachContactDrawer />}
        />
        <Route
          path="templates/:templateId"
          element={<EditTemplateDialog onSuccess={onTemplateSuccess} />}
        />
        <Route path="pitch-tracker/import/*" element={<ImportDialog />} />
      </Routes>
    </>
  );
};

export default ContactView;
