import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  Ref,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { ContactView } from "features/Influencer/Tracking/schema";
import { fetcherAuth } from "utils/api";

import { AlertContext } from "./Alert";
import { ContactViewContext } from "./ContactView";
import { OrganizationUserContext } from "./Organization";

export interface ContactViewSearchValue {
  query: string;
  page: number;
}

const PER_PAGE = 50;

interface ContactViewSearchContextInterface {
  contactViewParams: ContactViewSearchValue;
  viewLoading: boolean;
  setViewLoading: Dispatch<SetStateAction<boolean>>;
  allViewsLoading: boolean;
  fetchMoreLoading: boolean;
  tableRef: Ref<HTMLDivElement>;
  contactViews: ContactView[];
  handleSearch: (
    updatedValue: ContactViewSearchValue,
    contactViewId?: number,
  ) => void;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  contactViewParams: { query: "", page: 1 },
  viewLoading: true,
  setViewLoading: defaultContextMissingFunction,
  allViewsLoading: true,
  fetchMoreLoading: false,
  tableRef: null,
  contactViews: [],
  handleSearch: defaultContextMissingFunction,
};

const ContactViewSearchContext =
  createContext<ContactViewSearchContextInterface>(defaultInterface);

interface ContactViewSearchProviderProps {
  children: React.ReactNode;
}

const ContactViewSearchProvider = ({
  children,
}: ContactViewSearchProviderProps) => {
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const { setErrorAlert } = useContext(AlertContext);
  const { selectedView, setSelectedView, setOutreachContacts, setTotal } =
    useContext(ContactViewContext);
  const abortController = useRef<AbortController | undefined>(undefined);

  const [contactViews, setContactViews] = useState<ContactView[]>([]);
  const [contactViewParams, setContactViewParams] =
    useState<ContactViewSearchValue>(() => ({
      query: "",
      page: 1,
    }));
  const [allViewsLoading, setAllViewsLoading] = useState<boolean>(true);
  const [viewLoading, setViewLoading] = useState<boolean>(true);
  const [fetchMoreLoading, setFetchMoreLoading] = useState<boolean>(false);
  const tableRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    if (tableRef.current) {
      tableRef.current.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const fetchView = async (
    contactViewId: number,
    query?: string | null,
    page: number = 1,
  ) => {
    if (!currentOrg) {
      return;
    }

    if (abortController?.current) {
      abortController.current?.abort();
    }

    if (page === 1) {
      setViewLoading(true);
      scrollToTop();
    } else {
      setFetchMoreLoading(true);
    }
    let url = `/api/organization/${currentOrg?.id}/contact-views/${contactViewId}?page=${page}&per_page=${PER_PAGE}`;
    if (query) url += `&search_query=${query}`;
    try {
      const res = await fetcherAuth(
        getToken,
        url,
        "GET",
        {},
        {},
        false,
        false,
        true,
        abortController.current?.signal,
      );
      setSelectedView(res.contactView);
      if (page === 1) {
        setOutreachContacts(res.outreachContacts);
      } else {
        setOutreachContacts((prev) => [...prev, ...res.outreachContacts]);
      }
      setTotal(res.totalCount);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setViewLoading(false);
      setFetchMoreLoading(false);
    }
  };

  const handleSearch = (
    value: ContactViewSearchValue,
    contactViewId?: number,
  ) => {
    setContactViewParams(value);
    const viewId = contactViewId || selectedView?.id;
    if (viewId) fetchView(viewId, value.query, value.page);
  };

  const fetchViews = async () => {
    if (!currentOrg) {
      return;
    }

    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/contact-views`,
      );
      setContactViews(res.contactViews);
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setAllViewsLoading(false);
    }
  };

  useEffect(() => {
    fetchViews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id]);

  return (
    <ContactViewSearchContext.Provider
      value={{
        viewLoading,
        setViewLoading,
        allViewsLoading,
        contactViewParams,
        fetchMoreLoading,
        tableRef,
        contactViews,
        handleSearch,
      }}
    >
      {children}
    </ContactViewSearchContext.Provider>
  );
};

export { ContactViewSearchProvider, ContactViewSearchContext };
