import { Box, Button, Grid, IconButton, Tooltip } from "@mui/material";
import { SavedBrandContext } from "contexts/SavedBrand";
import { useContext } from "react";
import { SavedBrandCollection } from "schemas/dashboard";

import styles from "../styles";

interface Props {
  selectedCollection: SavedBrandCollection | null;
  toggleBulkMode: () => void;
  getTotal: (collectionId: number) => JSX.Element;
  editCollection: () => void;
  isBulkMode: boolean;
}

const ListHeader = ({
  selectedCollection,
  toggleBulkMode,
  getTotal,
  editCollection,
  isBulkMode,
}: Props) => {
  const { bulkBrands } = useContext(SavedBrandContext);
  return selectedCollection ? (
    <Grid
      container
      justifyContent="space-between"
      alignItems={"center"}
      sx={{ pl: 2, pr: 1, py: 1 }}
    >
      <Box>
        {selectedCollection?.name} {getTotal(selectedCollection.id)}
        {selectedCollection?.id && selectedCollection?.id > 0 ? (
          <Tooltip title="Edit Collection">
            <IconButton sx={styles.close} onClick={editCollection}>
              <Box component="i" className="fa-regular fa-edit" />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </Box>

      <Box>
        <Button
          size="small"
          onClick={toggleBulkMode}
          color={isBulkMode ? "primary" : "secondary"}
        >
          Select {bulkBrands?.length > 0 ? `(${bulkBrands?.length})` : ""}
        </Button>
      </Box>
    </Grid>
  ) : (
    <></>
  );
};

export default ListHeader;
