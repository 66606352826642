import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { BrandsContext, DiscoverParams, TagLabel } from "contexts/Brands";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { SubscriptionContext } from "contexts/Subscription";
import { UserRequestsContext } from "contexts/UserRequests";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import {
  COMPANY_TYPES,
  FOLLOWING_SIZES,
  SORTS,
  SearchParams,
} from "schemas/dashboard";
import theme from "styles/theme";

import { trackEvent } from "utils/tracking";
import { usePageSize } from "utils/usePageSize";
import { useSearchBrand } from "utils/useSearchBrand";

import VirtualAssistant from "../VirtualAssistant";
import DiscoverChips from "./Chips";
import CompanySizeFilter from "./CompanySizeFilter";
import LocationFilter from "./LocationFilter";
import SelectFilter from "./SelectFilter";
import { companyTypeIcons } from "./helpers";
import styles from "./styles";

interface Props {
  setOpenMobileCategoryFilter: Dispatch<SetStateAction<boolean>>;
}

export default function DiscoverFilter({ setOpenMobileCategoryFilter }: Props) {
  const { setOpen } = useContext(UserRequestsContext);
  const { canMakeBrandRequest, setUpgradeDialogSource } =
    useContext(SubscriptionContext);

  const { addCategoryQueryToSearch } = useSearchBrand();
  const [close, setClose] = useState(false);
  const { navigationWidth } = usePageSize();

  const { discoverFilterParams } = useContext(BrandsContext);
  const { discoverTab } = useContext(DiscoverViewContext);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobileScreen =
    isSmallScreen || (isLargeScreen && discoverTab !== null);

  const selectedTags = Object.keys(discoverFilterParams).reduce(
    (acc: TagLabel[], key: string) => {
      return acc.concat(discoverFilterParams[key as keyof DiscoverParams]);
    },
    [],
  );

  const isOptionSelected = (key: string, params: string) => {
    return Boolean(
      selectedTags?.find((x) => x?.key === key && x.params === params),
    );
  };

  const handleAddOption = (name: string, value: string) => {
    addCategoryQueryToSearch(name, value);
    setClose(true);
  };

  const selectedOptionIcon = (
    <Box
      component="i"
      className="fa-regular fa-check"
      sx={{ mr: 2, color: "success.main" }}
    />
  );

  const sortFilter = (
    <SelectFilter
      label={"Sort By"}
      options={SORTS}
      params={SearchParams.SORT_DISCOVER}
      setClose={setClose}
      close={close}
      isOptionSelected={isOptionSelected}
      selectedOptionIcon={selectedOptionIcon}
    />
  );

  const openCategoryFilter = () => {
    trackEvent("Cateogry Filter Toggled on Mobile View");
    setOpenMobileCategoryFilter((prev) => !prev);
  };

  const openRequest = () => {
    trackEvent("Request Button Clicked");
    if (!canMakeBrandRequest()) {
      setUpgradeDialogSource("Hits Brand Request Limit");
      return;
    }
    setOpen(true);
  };

  return (
    <>
      <Box sx={[styles.root, { width: navigationWidth }]}>
        <Grid container item xs md={12} justifyContent={"flex-end"}>
          <VirtualAssistant />
          <Button
            size="small"
            variant="contained"
            sx={styles.requestButton}
            disableElevation
            onClick={openRequest}
          >
            <Box
              component="i"
              className="fa-regular fa-message"
              sx={{ mr: 1 }}
            />
            {isMobileScreen ? "Request" : "Request Brand"}
          </Button>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          {!isMobileScreen && (
            <Grid item md={1.5} sx={{ pb: 0.5 }}>
              <Typography component="span">
                <strong>Categories</strong>
              </Typography>
            </Grid>
          )}

          <Grid
            container
            item
            xs={12}
            md
            sx={styles.filterOptionContainer}
            alignItems="center"
          >
            {isMobileScreen && (
              <Button
                variant="text"
                size="small"
                color={"inherit"}
                sx={{ textTransform: "none" }}
                onClick={openCategoryFilter}
              >
                Categories{" "}
                <Box
                  sx={{ ml: 0.5 }}
                  component="i"
                  className="fa-solid fa-bars-filter"
                />
              </Button>
            )}
            <SelectFilter
              label={"Type"}
              options={COMPANY_TYPES}
              params={SearchParams.TYPE_DISCOVER}
              setClose={setClose}
              close={close}
              isOptionSelected={isOptionSelected}
              selectedOptionIcon={selectedOptionIcon}
              iconsMap={companyTypeIcons}
            />

            {/* Location filter */}
            <LocationFilter
              handleAddOption={handleAddOption}
              close={close}
              setClose={setClose}
              selectedOptionIcon={selectedOptionIcon}
              isOptionSelected={isOptionSelected}
            />

            {/* Company Size Filter */}
            <CompanySizeFilter
              handleAddOption={handleAddOption}
              close={close}
              setClose={setClose}
              selectedOptionIcon={selectedOptionIcon}
              isOptionSelected={isOptionSelected}
            />

            <SelectFilter
              label={"Instagram Following"}
              options={FOLLOWING_SIZES}
              params={SearchParams.FOLLOWING_DISCOVER}
              setClose={setClose}
              close={close}
              isOptionSelected={isOptionSelected}
              selectedOptionIcon={selectedOptionIcon}
            />

            {isMobileScreen && sortFilter}
          </Grid>
          {!isMobileScreen && <Box sx={{ pr: 2 }}>{sortFilter}</Box>}
        </Grid>
        {isMobileScreen && (
          <DiscoverChips
            isMobileScreen={isMobileScreen}
            selectedTags={selectedTags}
          />
        )}
      </Box>

      {!isMobileScreen && (
        <Grid container sx={isMobileScreen ? {} : styles.chipRoot}>
          <Grid item md={1.5} />
          <DiscoverChips
            isMobileScreen={isMobileScreen}
            selectedTags={selectedTags}
          />
        </Grid>
      )}
    </>
  );
}
