export const ON_BEHALF_OF_TOKEN = "onBehalfOfToken";
export const HAS_WATCHED_ONBOARDING_VIDEO = "hasWatchedOnboardingVideo";
export const SORT_PROJECT_PAGE = "sortProjectPage";
export const FEEDBACK_GIVEN = "feedbackGiven";
export const SCHEDULED_DATE_EMAIL = "scheduledDateEmail";
export const DISCOVER_SEARCH_PARAMS = "discoverSearchParams";
export const DISCOVER_METADATA_TAGS = "discoverMetadataTags";
export const CATEGORY_ALL_FILTER = "categoryFilterAll";

// DIALOGS
export const DISPLAY_SCHEDULED_DIALOG = "displayScheduleDialog";
export const DISPLAYED_CITY_DIALOG = "displayedCityDialog";
export const DISPLAYED_CONSENT_DIALOG = "displayedConsentDialog";
export const DISPLAYED_SIMILAR_BRANDS_DIALOG = "displayedSimilarBrandsDialog";
