import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { UserRequestsContext } from "contexts/UserRequests";
import { useContext, useState } from "react";
import { UserRequestSource } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { isValidHttpUrl, isValidInstagramUsername } from "utils/string";
import { useSearchBrand } from "utils/useSearchBrand";

import styles from "./styles";

export default function RequestBrandDialog() {
  const theme = useTheme();
  const { handleRequestForBrand } = useSearchBrand();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { open, setOpen } = useContext(UserRequestsContext);

  const [text, setText] = useState("");
  const [textValidation, setTextValidation] = useState<undefined | string>(
    undefined,
  );

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e: CustomEvent) => {
    const { value } = e.target;
    setText(value);

    if (isValidRequestFor(value.trim())) {
      setTextValidation(undefined);
    }
  };

  const isValidRequestFor = (description: string) => {
    if (description.includes("instagram")) {
      if (isValidInstagramUsername(description)) {
        return true;
      }
    } else if (isValidHttpUrl(description)) {
      return true;
    }
    return false;
  };

  const handleRequest = async () => {
    const description = text.trim();

    if (!isValidRequestFor(description)) {
      setTextValidation("Please enter a valid URL or instagram URL");
      return;
    }
    setLoading(true);
    await handleRequestForBrand(
      description,
      UserRequestSource.SOURCE_REQUEST_BRAND,
    );
    setLoading(false);
    setText("");
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={isMobileScreen}>
      <DialogTitle>
        <Typography textAlign="center" variant="h6">
          Can't Find A Specific Brand?
        </Typography>

        <IconButton onClick={handleClose} sx={styles.close}>
          <Box component="i" className="fa-regular fa-xmark" />
        </IconButton>
      </DialogTitle>
      <form
        onSubmit={(e: CustomEvent) => {
          e.preventDefault();
          handleRequest();
        }}
      >
        <DialogContent sx={{ justifyContent: "center" }}>
          <strong>
            Share either the brand's website URL or Instagram URL:
          </strong>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <TextField
              placeholder="Website or Instagram link"
              value={text}
              onChange={handleChange}
              error={!!textValidation}
              helperText={textValidation}
            />
          </FormControl>
          <Typography variant="body2" paragraph sx={{ mt: 1 }}>
            🔔 We'll notify you when we add them to the dashboard.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ mb: 2 }}>
          <Grid container justifyContent="center">
            <LoadingButton
              disableElevation
              variant="contained"
              disabled={!text || text?.trim()?.length === 0}
              loading={loading}
              type="submit"
            >
              Submit
            </LoadingButton>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
}
