import {
  Backdrop,
  Box,
  CircularProgress,
  Drawer,
  Grid,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  InboxSidebarStatusMap,
  OutreachContactsContext,
} from "contexts/OutreachContacts";
import { useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Link as RouterLink } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { OutreachContact } from "schemas/dashboard";
import { SetAlertType } from "schemas/functions";
import { routes } from "schemas/routes";

import { makeDeepCopy } from "utils/updateLocalState";

import ComposeEmailDrawer from "../ComposeEmailDrawer";
import OutreachContactDrawer from "../OutreachContactDrawer";
import ContactRow from "./ContactRow";
import ScheduledContactRow from "./ContactRow/scheduled";
import SortSelect from "./Filters/SortSelect";
import StarredSelect from "./Filters/StarredSelect";
import SearchBar from "./SearchBar";
import Sidebar from "./Sidebar";
import styles from "./styles";

interface Props {
  setAlert: SetAlertType;
}

const REF_INDEX = 20;

const OutreachTable = ({ setAlert }: Props) => {
  const {
    contacts,
    fetchLoading,
    fetchMoreLoading,
    hitsLimit,
    setFetchContactsParams,
    currentSidebarOption,
    isFiltering,
  } = useContext(OutreachContactsContext);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  const [ref, isRefVisible] = useInView({
    rootMargin: "0px 0px",
  });

  const clearFilters = () => {
    setFetchContactsParams((prev) => {
      const copy = makeDeepCopy(prev);
      copy.page = 1;
      copy.selectedSuggestion = null;
      copy.importantOnly = false;
      return copy;
    });
  };

  const openSent = () => {
    setFetchContactsParams((prev) => {
      const copy = makeDeepCopy(prev);
      copy.page = 1;
      copy.selectedSuggestion = null;
      copy.importantOnly = false;
      copy.statuses = InboxSidebarStatusMap.sent;
      return copy;
    });
  };

  useEffect(() => {
    if (isRefVisible) {
      if (!hitsLimit && !fetchLoading) {
        setFetchContactsParams((prev) => {
          const copy = makeDeepCopy(prev);
          copy.page = prev.page + 1;
          return copy;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefVisible, fetchLoading]);

  const toggleSidebar = (newOpen: boolean) => () => {
    setSidebarOpen(newOpen);
  };

  return (
    <>
      <Grid container>
        {!isSmallScreen && (
          <Grid item container lg={2}>
            <Box sx={{ mt: 8, width: "100%", pr: 3 }}>
              <Sidebar />
            </Box>
          </Grid>
        )}
        <Grid
          item
          container
          alignItems="flex-start"
          sx={{ mt: 2 }}
          xs={12}
          lg={10}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            item
            xs={12}
            sx={{ pb: 2 }}
          >
            {isSmallScreen && (
              <Grid item xs="auto">
                <IconButton
                  size="small"
                  color="primary"
                  onClick={toggleSidebar(true)}
                >
                  <Box component="i" className="fa-solid fa-bars" />
                </IconButton>
                <Drawer
                  PaperProps={{
                    sx: { minWidth: "200px" },
                  }}
                  open={sidebarOpen}
                  onClose={toggleSidebar(false)}
                >
                  <Sidebar onSelect={toggleSidebar(false)} isSmallScreen />
                </Drawer>
              </Grid>
            )}

            <Grid item xs>
              <SearchBar setAlert={setAlert} />
            </Grid>
            <Grid item xs={12} lg="auto">
              <Box sx={{ width: isSmallScreen ? "inherit" : 200 }}>
                <SortSelect />
              </Box>
            </Grid>
            {currentSidebarOption?.key === "inbox" && (
              <Grid item xs={12}>
                <StarredSelect />
              </Grid>
            )}
          </Grid>
          {!fetchLoading && contacts && contacts.length === 0 && (
            <Box sx={styles.noContacts}>
              <Typography component="div" variant="h4" gutterBottom>
                {isFiltering
                  ? `No contacts found`
                  : `Empty ${currentSidebarOption?.label.toLowerCase()} folder`}
              </Typography>
              {isFiltering ? (
                <>
                  <Typography component="div" paragraph variant="h6">
                    Your filters are hiding your results, try{" "}
                    <Link sx={{ cursor: "pointer" }} onClick={clearFilters}>
                      clearing filters
                    </Link>
                    .
                  </Typography>
                </>
              ) : (
                <>
                  {currentSidebarOption?.key !== "sent" ? (
                    <>
                      <Typography component="div" paragraph variant="h6">
                        To see all of your sent emails,{" "}
                        <Link sx={{ cursor: "pointer" }} onClick={openSent}>
                          open your sent folder
                        </Link>
                        .
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography component="div" paragraph variant="h6">
                        Send emails in the{" "}
                        <RouterLink to={`/${routes.brands}`}>
                          brands tab
                        </RouterLink>{" "}
                        and monitor their status in this tab.
                      </Typography>
                    </>
                  )}
                </>
              )}
            </Box>
          )}
          <Grid
            item
            xs={12}
            sx={[
              styles.contacts,
              {
                height: {
                  xs:
                    currentSidebarOption?.key === "inbox"
                      ? "calc(100vh - 270px)"
                      : "calc(100vh - 220px)",
                  lg:
                    currentSidebarOption?.key === "inbox"
                      ? "calc(100vh - 225px)"
                      : "calc(100vh - 175px)",
                },
              },
            ]}
          >
            {contacts &&
              contacts.length > 0 &&
              contacts.map((row: OutreachContact, idx: number) => (
                <Grid
                  item
                  xs={12}
                  key={row?.id}
                  ref={
                    (
                      contacts.length > REF_INDEX
                        ? idx === contacts.length - REF_INDEX
                        : idx === contacts.length - 1
                    )
                      ? ref
                      : undefined
                  }
                >
                  {currentSidebarOption?.key === "scheduled" ? (
                    <ScheduledContactRow contact={row} setAlert={setAlert} />
                  ) : (
                    <ContactRow contact={row} setAlert={setAlert} />
                  )}
                </Grid>
              ))}
            {!fetchLoading && fetchMoreLoading && (
              <Grid
                item
                container
                justifyContent="center"
                xs={12}
                sx={{ mt: 2, mb: 10 }}
              >
                <CircularProgress size={30} disableShrink />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      {fetchLoading && (
        <Backdrop sx={{ zIndex: 9999 }} open={fetchLoading}>
          <CircularProgress />
        </Backdrop>
      )}
      <Routes>
        <Route
          path=":outreachContactId/*"
          element={<OutreachContactDrawer />}
        />
        <Route path="compose" element={<ComposeEmailDrawer />} />
      </Routes>
    </>
  );
};
export default OutreachTable;
