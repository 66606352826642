import { Box, Button } from "@mui/material";
import { ContactViewContext } from "contexts/ContactView";
import { OutreachTemplatesContext } from "contexts/OutreachTemplates";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { OutreachContact } from "schemas/dashboard";

interface IProps {
  outreachContact: OutreachContact;
}

const styles = {
  root: {
    mx: 1,
    my: 0.5,
    display: "flex",
    justifyContent: "center",
  },
  smallButton: {
    textTransform: "none",
    textDecoration: "underline",
    fontSize: 12,
    height: 25,
    "&:hover": {
      textDecoration: "underline",
    },
  },
};

export default function TableTemplateUsed({ outreachContact }: IProps) {
  const { outreachTemplates } = useContext(OutreachTemplatesContext);
  const { trackTable } = useContext(ContactViewContext);
  const navigate = useNavigate();
  if (!outreachContact.latestOutreachTemplateId) {
    return <Box sx={styles.root} />;
  }

  const outreachTemplate = outreachTemplates.find(
    (t) => t.id === outreachContact.latestOutreachTemplateId,
  );

  if (!outreachTemplate) {
    return <Box sx={styles.root} />;
  }

  return (
    <Box sx={styles.root}>
      <Button
        disableElevation
        tabIndex={-1}
        variant="text"
        color="inherit"
        sx={styles.smallButton}
        onClick={() => {
          trackTable("Tracking Table Template Button Pressed", {
            templateId: outreachTemplate.id,
            templateName: outreachTemplate.name,
            "Outreach Contact ID": outreachContact?.id,
            "Outreach Contact Email": outreachContact?.email,
          });
          navigate(`templates/${outreachTemplate.id}`);
        }}
      >
        {outreachTemplate.name}
      </Button>
    </Box>
  );
}
