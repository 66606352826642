import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { AutomatedContactProperty, PropertyType } from "schemas/dashboard";
import { CustomEvent } from "schemas/functions";

import { TABLE_FILTER } from "features/Influencer/Tracking/events";
import {
  BuiltInFilters,
  ContactViewProperty,
  FiltersType,
} from "features/Influencer/Tracking/schema";

import { ContactViewContext } from "./ContactView";

interface TrackingColumnsFiltersContextInterface {
  handleOpenFilterMenu: (e: CustomEvent) => void;
  handleCloseFilterMenu: () => void;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  filterableColumns?: ContactViewProperty[];
  highlightColumn: ContactViewProperty | null;
  setHighlightColumn: Dispatch<SetStateAction<ContactViewProperty | null>>;
  filterButtonRef: any;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  handleCloseFilterMenu: defaultContextMissingFunction,
  handleOpenFilterMenu: defaultContextMissingFunction,
  open: false,
  anchorEl: null,
  filterableColumns: [],
  highlightColumn: null,
  setHighlightColumn: defaultContextMissingFunction,
  filterButtonRef: null,
};

const TrackingColumnsFiltersContext =
  createContext<TrackingColumnsFiltersContextInterface>(defaultInterface);

interface TrackingColumnsFiltersProviderProps {
  children: React.ReactNode;
}

const TrackingColumnsFiltersProvider = ({
  children,
}: TrackingColumnsFiltersProviderProps) => {
  const [highlightColumn, setHighlightColumn] =
    useState<ContactViewProperty | null>(null);
  const { selectedView, trackTable } = useContext(ContactViewContext);
  const filterButtonRef = React.useRef(null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleOpenFilterMenu = (event: CustomEvent) => {
    trackTable(TABLE_FILTER);
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFilterMenu = () => {
    setAnchorEl(null);
    setHighlightColumn(null);
  };

  const open = Boolean(anchorEl);

  const isSelectable = (x: ContactViewProperty) => {
    if (
      selectedView?.name !== "All" &&
      x.contactProperty?.automatedProperty === AutomatedContactProperty.stage
    ) {
      return false;
    }
    if (x.contactPropertyName && x.contactPropertyName in BuiltInFilters) {
      return true;
    }
    if (
      x.contactProperty?.type &&
      FiltersType?.includes(x.contactProperty?.type)
    ) {
      if (x.contactProperty?.type === PropertyType.checkbox) {
        return true;
      } else {
        return Boolean(
          x?.contactProperty?.selectOptions &&
            x?.contactProperty?.selectOptions?.length > 0,
        );
      }
    }
    return false;
  };

  const filterableColumns = selectedView?.contactViewProperties?.filter(
    (x: ContactViewProperty) => isSelectable(x),
  );

  return (
    <TrackingColumnsFiltersContext.Provider
      value={{
        handleOpenFilterMenu,
        handleCloseFilterMenu,
        open,
        anchorEl,
        filterableColumns,
        highlightColumn,
        setHighlightColumn,
        filterButtonRef,
      }}
    >
      {children}
    </TrackingColumnsFiltersContext.Provider>
  );
};

export { TrackingColumnsFiltersProvider, TrackingColumnsFiltersContext };
