export const routes = {
  dashboard: "dashboard",
  onboarding: "onboarding",
  onboardingInfluencer: "start-onboarding",
  signIn: "sign-in",
  signUp: "sign-up",
  founder: "user",
  influencer: "start",
  tracking: "tracking",

  // Onboarding
  aboutOnboarding: "start-onboarding/about",
  categoryOnboarding: "start-onboarding/category",
  recommendationOnboarding: "start-onboarding/recommendation",
  pitchOnboarding: "start-onboarding/pitch",
  customizePartOneOnboarding: "start-onboarding/customize/1",
  customizePartTwoOnboarding: "start-onboarding/customize/2",
  customizeCategoryOnboarding: "start-onboarding/categories",
  selectBrandOnboarding: "start-onboarding/select-brand",
  emailOnboarding: "start-onboarding/email-brand",
  connectGmailOnboarding: "start-onboarding/connect",

  // Dashboard
  inbox: "dashboard/inbox",
  brands: "dashboard/brands",
  opportunities: "dashboard/opportunities",
  settings: "dashboard/settings",
  profile: "dashboard/profile",
  templates: "dashboard/templates",
  pitchTrackerImport: "pitch-tracker/import",
  recommendations: "dashboard/recommendations",

  // Tracking
  trackingView: "dashboard/tracking/view",

  // Templates
  create: "create",
  new: "new",
  import: "import",
  newTemplate: "new-template",

  // Tasks
  tasks: "dashboard/tasks",

  // QuickSend
  outreachSend: "dashboard/outreach/send",
  discoverSend: "dashboard/brands/all/send",
  tasksSend: "dashboard/tasks/all/send",
};

export const isOnPage = (path: string) => {
  return window.location.href?.includes(path);
};

export type Route = {
  name: string;
  element: any;
};
