const styles = {
  planDescription: {
    mb: 2,
  },
  warningText: {
    color: "#A93226",
  },
  textContainer: {
    mb: { xs: 0, md: 1 },
  },
  chip: {
    ml: 1,
    color: "inherit",
    borderRadius: 2,
  },
};

export default styles;
