import { Backdrop, Box, CircularProgress, Tab, Tabs } from "@mui/material";
import { ContactViewSearchContext } from "contexts/ContactViewSearch";
import { useContext, useEffect, useMemo } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { CustomEvent } from "schemas/functions";
import { routes } from "schemas/routes";

import { PAGE_VISITED } from "constants/trackingProps";
import { trackEvent } from "utils/tracking";

import ContactView from "./ContactView";
import styles from "./styles";

const InfluencerTracking = () => {
  const { contactViews, allViewsLoading } = useContext(
    ContactViewSearchContext,
  );
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    trackEvent(PAGE_VISITED);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getView = (viewName: string) => {
    return contactViews.find((view) => view.name === viewName);
  };

  const handleChange = (event: CustomEvent, newValue: string) => {
    event.stopPropagation();
    const newView = getView(newValue);
    if (newView?.id) {
      navigate(`/${routes.trackingView}/${newView.id}`);
    }
  };

  const contactViewId = useMemo(() => {
    const match = location.pathname.match(/\/view\/(\d+)\/?/);
    if (match && match[1]) {
      return Number(match[1]);
    }
  }, [location.pathname]);

  if (contactViews.length > 0 && !contactViewId) {
    const pitchingView = getView("Pitching");
    // TODO: have a fallback for nonexisting views
    if (pitchingView) {
      return (
        <Navigate to={`/${routes.trackingView}/${pitchingView.id}`} replace />
      );
    }
  }

  const contactView = contactViews.find((view) => view.id === contactViewId);

  return (
    <Box sx={styles.root}>
      {allViewsLoading && (
        <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={allViewsLoading}>
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 2 }}>
        <Tabs
          sx={{ px: 1 }}
          variant="scrollable"
          scrollButtons="auto"
          value={contactView?.name}
          onChange={handleChange}
        >
          <Tab
            sx={[styles.tab, styles.firstTab]}
            value="Pitching"
            label="Pitching"
          />
          <Tab sx={styles.tab} value="In Discussion" label="In Discussion" />
          <Tab sx={styles.tab} value="Collaboration" label="Collaboration" />
          <Tab
            sx={styles.tab}
            value="Reach Out Later"
            label="Reach Out Later"
          />
          <Tab sx={[styles.tab, styles.lastTab]} value="All" label="All" />
        </Tabs>
      </Box>

      <Box sx={styles.tableContainer}>
        <Routes>
          <Route path="view/:contactViewId/*" element={<ContactView />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default InfluencerTracking;
