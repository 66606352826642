import { createContext, useContext } from "react";
import { BentoBrand, BrandRecommendation, SavedBrand } from "schemas/dashboard";

import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import { DiscoverTabView, DiscoverViewContext } from "./DiscoverView";
import { QuickSendDrawerContext } from "./QuickSendDrawer";
import { SavedBrandContext } from "./SavedBrand";
import { SavedBrandCollectionsContext } from "./SavedBrandCollections";
import { TopRecommendationsContext } from "./TopRecommendations";

export type UpdateLocalPagination = {
  bentoBrand: BentoBrand;
  handleClose: () => void;
};

export type NavigationBody = {
  bentoBrand: BentoBrand;
  handleClose: () => void;
  currentPosition: number;
  paginationSource?: string;
};

export enum PaginationSource {
  TOP_RECOMMENDATION = "Top Recommendation",
  SAVED_BRANDS = "Saved Brands",
}

export type PaginationType = SavedBrand | BentoBrand | BrandRecommendation;
export type PaginationListType =
  | SavedBrand[]
  | BrandRecommendation[]
  | BentoBrand[];

interface QuickSendPaginationContextInterface {
  updatePaginationLocalState: (body: UpdateLocalPagination) => void;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  updatePaginationLocalState: defaultContextMissingFunction,
};

const QuickSendPaginationContext =
  createContext<QuickSendPaginationContextInterface>(defaultInterface);

interface QuickSendPaginationProviderProps {
  children: React.ReactNode;
}

const QuickSendPaginationProvider = ({
  children,
}: QuickSendPaginationProviderProps) => {
  const { selectedCollection } = useContext(SavedBrandCollectionsContext);
  const { getId } = useContext(SavedBrandContext);
  const { handleOpenQuickSendIndividual } = useContext(QuickSendDrawerContext);
  const { collectionsMap, setCollectionsMap } = useContext(
    SavedBrandCollectionsContext,
  );
  const { setTopRecommendations, topRecommendations } = useContext(
    TopRecommendationsContext,
  );
  const { setDiscoverTab, discoverTab } = useContext(DiscoverViewContext);

  const getCollectionId = (source?: string) => {
    if (source?.includes(PaginationSource.SAVED_BRANDS)) {
      const collectionId = source.split("-")?.[1];
      return Number(collectionId);
    }
    return 0;
  };

  const getPaginationList = (source?: string) => {
    if (!source) return [];
    if (source === PaginationSource.TOP_RECOMMENDATION) {
      return topRecommendations;
    } else {
      const collectionId = getCollectionId(source);
      return collectionsMap[collectionId] ?? [];
    }
  };

  const isSavedBrandSource = (source?: string) => {
    return source && source?.includes(PaginationSource.SAVED_BRANDS);
  };

  const isBrandInList = (bentoBrandId: number, source: PaginationSource) => {
    const brands = getPaginationList(source);
    return brands.findIndex((brand) => getId(brand) === bentoBrandId) > -1;
  };

  const updatePaginationLocalState = (body: UpdateLocalPagination) => {
    let { bentoBrand, handleClose } = body;

    const source = getPaginationSource(bentoBrand);
    const brandsList = getPaginationList(source);

    if (!source) {
      handleClose();
      return;
    }

    const index = brandsList.findIndex(
      (brand) => Number(bentoBrand.id) === getId(brand),
    );
    const currentPosition = Math.max(index, 0);

    let updatedBody = {
      ...body,
      currentPosition,
      paginationSource: source,
    };

    if (isSavedBrandSource(source)) {
      setCollectionsMap((prev) => {
        const copy = makeDeepCopy(prev);
        const collectionId = getCollectionId(source);
        copy[collectionId] = update(updatedBody, prev[collectionId] ?? []);
        return copy;
      });
    }
    if (source === PaginationSource.TOP_RECOMMENDATION) {
      setTopRecommendations(
        (prev) => update(updatedBody, prev) as BrandRecommendation[],
      );
    }
  };

  const update = (body: NavigationBody, prevBrands: PaginationListType) => {
    const { bentoBrand, handleClose, currentPosition, paginationSource } = body;

    const index = prevBrands?.findIndex(
      (brand: SavedBrand | BentoBrand) =>
        Number(bentoBrand?.id) === getId(brand),
    );
    if (index === -1) {
      return prevBrands;
    }
    let positionToMoveTo = currentPosition + 1;
    // Close drawer or move to the next brand
    if (
      positionToMoveTo < 0 ||
      positionToMoveTo >= prevBrands?.length ||
      !paginationSource
    ) {
      handleClose();
    } else {
      handleNavigate(positionToMoveTo, prevBrands, paginationSource);
    }
    return prevBrands;
  };

  const handleNavigate = (
    index: number,
    brands: SavedBrand[] | BrandRecommendation[] | BentoBrand[],
    paginationSource: string,
  ) => {
    // Move to the next brand on the list;
    const brandAtThatPosition = brands?.[index];
    const bentoBrandId = getId(brandAtThatPosition);
    const bentoBrand =
      "bentoBrand" in brandAtThatPosition
        ? brandAtThatPosition.bentoBrand
        : brandAtThatPosition;
    trackEvent("Next Brand Redirected", { "Bento Brand ID": bentoBrandId });
    handleOpenQuickSendIndividual(
      bentoBrandId,
      "Navigate Next",
      undefined,
      true,
      bentoBrand,
    );
    if (
      paginationSource !== PaginationSource.TOP_RECOMMENDATION &&
      discoverTab !== DiscoverTabView.SAVED_BRANDS
    ) {
      setDiscoverTab(DiscoverTabView.SAVED_BRANDS);
    }
  };

  const getPaginationSource = (bentoBrand: BentoBrand) => {
    if (selectedCollection) {
      const brands = collectionsMap[selectedCollection?.id];
      const inCollection = brands?.find(
        (sb: SavedBrand) => Number(sb.bentoBrandId) === bentoBrand.id,
      );
      if (inCollection) {
        return `${PaginationSource.SAVED_BRANDS}-${selectedCollection?.id}`;
      }
    }
    const isTopRecommendations = isBrandInList(
      Number(bentoBrand.id),
      PaginationSource.TOP_RECOMMENDATION,
    );
    if (isTopRecommendations) return PaginationSource.TOP_RECOMMENDATION;
  };

  return (
    <QuickSendPaginationContext.Provider
      value={{
        updatePaginationLocalState,
      }}
    >
      {children}
    </QuickSendPaginationContext.Provider>
  );
};

export { QuickSendPaginationProvider, QuickSendPaginationContext };
