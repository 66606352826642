import { LoadingButton } from "@mui/lab";
import {
  Box,
  Chip,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  TextField,
  Tooltip,
  darken,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { grey } from "@mui/material/colors";
import { ContactViewContext } from "contexts/ContactView";
import { isArray } from "lodash";
import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import {
  AutomatedContactProperty,
  OutreachContact,
  PropertyType,
} from "schemas/dashboard";
import { CustomEvent, Map } from "schemas/functions";

import ColorPicker from "components/ColorPicker";
import {
  COLOR_SELECTED,
  COLOR_VIEWED,
} from "features/Influencer/Tracking/events";
import {
  ContactViewProperty,
  SELECT_COLOR_OPTIONS,
  SelectOption,
} from "features/Influencer/Tracking/schema";
import { makeDeepCopy } from "utils/updateLocalState";
import useTableMenu from "utils/useTableMenu";

import DeleteAutomatedOptionDialog from "../DeleteAutomatedOptionDialog";
import { getChipNameFromOptions } from "../helpers";
import { selectCellStyles } from "./styles";

interface Props {
  outreachContact: OutreachContact;
  property: ContactViewProperty;
  value?: string | string[];
  isEditable?: boolean;
  type: PropertyType;
  options?: SelectOption[];
}

const TableSelect = forwardRef((props: Props, ref) => {
  const {
    value,
    property,
    outreachContact,
    isEditable = true,
    type,
    options,
  } = props;
  const {
    setOutreachContacts,
    updateOutreachContactPropertyValues,
    trackCell,
  } = useContext(ContactViewContext);

  // States
  const [displayWarningMenu, setDisplayWarningMenu] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [loading, setLoading] = useState(false);

  const { updateProperty } = useContext(ContactViewContext);
  const [newTag, setNewTag] = useState<SelectOption>({
    name: "",
    color: "#C5B9DE",
  });
  const { anchorEl, setAnchorEl, open, popperRef, menuOnBlur, handleClose } =
    useTableMenu();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    trackCell(property, outreachContact, {
      Action: "Select Dropdown Opened",
    });
  };

  useImperativeHandle(ref, () => {
    return {
      childRefClick(e: CustomEvent) {
        handleClick(e);
      },
      childRefKeyPressed(e: CustomEvent) {
        if (document.activeElement?.tagName.toLowerCase() === "input") {
          return;
        }
        if (e.key === "Enter") {
          handleClick(e);
        }
      },
      childRefOnBlur(e: CustomEvent) {
        menuOnBlur(e);
      },
    };
  });

  const styles = selectCellStyles(isEditable, open);

  const getChipStyles = (color: string) => {
    const bg = color;
    const border = `1px solid ${grey[300]} `;
    const text = darken(color, 0.9);
    return {
      backgroundColor: bg,
      color: text,
      border,
      borderRadius: 2,
      fontSize: 10,
      width: "fit-content",
      cursor: isEditable ? "pointer" : "default",
    };
  };

  const getChip = (v: string) => {
    const matched = getChipNameFromOptions(property, v, options || []);
    if (!matched) return <></>;
    return (
      <Chip
        size="small"
        key={matched.id}
        label={matched.name}
        sx={getChipStyles(matched.color)}
      />
    );
  };

  const renderChips = () => {
    const chips = [];
    if (isArray(value) && value?.length > 0) {
      for (const v of value) {
        chips.push(getChip(v));
      }
      return <>{chips?.map((x) => x)}</>;
    } else if (value && value?.length > 0) {
      return getChip(value?.toString());
    } else if (isEditable) {
      return <Box sx={{ cursor: "pointer" }} />;
    } else {
      return <Box sx={{ pointerEvents: "none" }} />;
    }
  };

  const isSelected = (optionId: string) => {
    return value === optionId || value?.includes(optionId);
  };

  const handleAddCustom = (pId: number, pValues: Map, option: SelectOption) => {
    if (type === PropertyType.multipleSelect) {
      if (!pValues[pId]) pValues[pId] = [];
      pValues[pId].push(option.id);
    }
    if (type === PropertyType.singleSelect) pValues[pId] = option.id;
    return pValues;
  };

  const handleRemoveCustom = (pId: number, pValues: Map, optionId?: string) => {
    if (!optionId) {
      return pValues;
    }
    if (type === PropertyType.multipleSelect && pValues[pId]) {
      pValues[pId] = pValues[pId]?.filter((x: string) => x !== optionId);
    }
    if (type === PropertyType.singleSelect && pValues[pId] === optionId) {
      pValues[pId] = null;
    }
    return pValues;
  };

  const handleSelectOption = (option: SelectOption) => {
    const pId = property?.contactPropertyId;

    if (!outreachContact.id || !pId) {
      return;
    }

    setOutreachContacts((prev) => {
      let pValues;
      const copy = prev.map((contact) => {
        if (contact.id !== outreachContact.id) {
          return contact;
        }
        pValues = { ...contact.propertyValues };
        if (option?.id && isSelected(option.id)) {
          pValues = handleRemoveCustom(pId, pValues, option.id);
        } else {
          pValues = handleAddCustom(pId, pValues, option);
        }
        return {
          ...contact,
          propertyValues: pValues,
        };
      });
      if (pValues && pId && outreachContact.id) {
        updateOutreachContactPropertyValues(
          outreachContact.id,
          pId,
          pValues[pId],
        );
      }
      return copy;
    });
  };

  const handleChange = (e: CustomEvent) => {
    const value = e.target.value;
    setNewTag((prev) => ({
      ...prev,
      name: value,
    }));
  };

  const saveTag = async (e: CustomEvent) => {
    e.stopPropagation();
    setLoading(true);
    const selections = makeDeepCopy(property?.contactProperty?.selectOptions);
    if (newTag?.id) {
      const index = selections?.findIndex(
        (x: SelectOption) => x.id === newTag?.id,
      );
      selections[index] = newTag;
    } else {
      selections.push(newTag);
    }
    if (property?.contactProperty) {
      await updateProperty(property?.contactProperty, selections);
    }
    setNewTag({ name: "", color: newTag.color });
    setLoading(false);
    setDisplayColorPicker(false);
  };

  const removeTag = (e?: CustomEvent, confirmRemoveAutomatedOption = false) => {
    if (e) e.stopPropagation();

    if (newTag?.automatedName && !confirmRemoveAutomatedOption) {
      setDisplayWarningMenu(true);
      return;
    }

    const selections = makeDeepCopy(
      property?.contactProperty?.selectOptions,
    )?.filter((x: SelectOption) => x.id !== newTag.id);

    if (property?.contactProperty) {
      updateProperty(property?.contactProperty, selections);
      // remove the tag from each outreach contact
      setOutreachContacts((prev) =>
        prev.map((outreachContact: OutreachContact) => {
          if (
            property.contactProperty?.id &&
            outreachContact.propertyValues?.[property.contactProperty?.id]
          ) {
            let pValues = { ...outreachContact.propertyValues };
            pValues = handleRemoveCustom(
              property.contactProperty.id,
              pValues,
              newTag.id,
            );
            return {
              ...outreachContact,
              propertyValues: pValues,
            };
          } else {
            return outreachContact;
          }
        }),
      );
    }

    setNewTag({ name: "", color: newTag.color });
  };

  const handleConfirmDeleteAutomatedField = () => {
    setDisplayWarningMenu(false);
    removeTag(undefined, true);
  };

  const setColor = (color: string) => {
    trackCell(property, outreachContact, {
      Action: COLOR_SELECTED,
    });
    setNewTag((prev) => ({
      ...prev,
      color,
    }));
  };

  const handleEdit = (e: CustomEvent, tag: SelectOption) => {
    e.stopPropagation();
    setNewTag(tag);
  };

  const handleOpenColorPicker = (e: CustomEvent) => {
    e.stopPropagation();
    trackCell(property, outreachContact, {
      Action: COLOR_VIEWED,
    });
    setDisplayColorPicker((prev) => !prev);
  };

  const renderMenu = () => {
    return (
      <>
        {!isEditable && (
          <Box sx={{ fontSize: 12 }}>
            You cannot edit this field. It is automated by Bento.
          </Box>
        )}
        <MenuList dense sx={{ outline: "none" }}>
          {options?.map((option) => (
            <MenuItem
              selected={
                !isEditable
                  ? false
                  : option.id && typeof value === "object" && value
                    ? value.includes(option.id)
                    : value === option.id
              }
              key={option.id}
              onClick={(e: CustomEvent) => {
                e.stopPropagation();
                handleSelectOption(option);
              }}
            >
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                {option?.automatedName && (
                  <Tooltip title="This option is automatically set by Bento">
                    <Box
                      component="i"
                      className="fa-solid fa-wand-magic-sparkles"
                      sx={{ color: "#2362C1", mr: 1 }}
                    />
                  </Tooltip>
                )}
                <Grid container item xs={9} alignItems="center">
                  <Chip
                    size="small"
                    label={option.name}
                    sx={getChipStyles(option.color)}
                  />
                </Grid>

                {isEditable && (
                  <IconButton
                    sx={styles.editTag}
                    onClick={(e: CustomEvent) => {
                      e.stopPropagation();
                      handleEdit(e, option);
                    }}
                  >
                    <Box component="i" className="fa-regular fa-pencil" />
                  </IconButton>
                )}
              </Grid>
            </MenuItem>
          ))}
        </MenuList>
        {isEditable && options && options?.length > 0 && <Divider />}
        {isEditable && (
          <Grid
            gap={1}
            container
            alignItems="center"
            sx={{ px: 2 }}
            onClick={(e: CustomEvent) => e.stopPropagation()}
          >
            <IconButton
              onClick={handleOpenColorPicker}
              sx={[
                styles.tagColor,
                {
                  backgroundColor: newTag.color,
                  "&:hover": { backgroundColor: newTag.color },
                },
              ]}
            />
            <Tooltip
              title={
                property.contactProperty?.automatedProperty ===
                  AutomatedContactProperty.stage &&
                !!newTag.automatedName &&
                "You cannot edit the name of this automated stage"
              }
            >
              <TextField
                disabled={
                  property.contactProperty?.automatedProperty ===
                    AutomatedContactProperty.stage && !!newTag.automatedName
                }
                sx={{ width: 200 }}
                placeholder="Add your own tag"
                variant="standard"
                onChange={handleChange}
                onKeyDown={(e: CustomEvent) => e.stopPropagation()}
                value={newTag?.name}
              />
            </Tooltip>
          </Grid>
        )}
        {displayColorPicker && (
          <Box onClick={(event) => event.stopPropagation()}>
            <ColorPicker
              color={newTag?.color}
              setColor={setColor}
              colors={SELECT_COLOR_OPTIONS}
            />
          </Box>
        )}
        {isEditable && (
          <LoadingButton
            onClick={saveTag}
            size="small"
            sx={styles.save}
            disabled={!newTag?.name}
            loading={loading}
          >
            Save
          </LoadingButton>
        )}
        {newTag?.id &&
          !(
            property.contactProperty?.automatedProperty ===
              AutomatedContactProperty.stage && !!newTag.automatedName
          ) && (
            <span>
              <LoadingButton
                onClick={removeTag}
                size="small"
                sx={styles.save}
                color="secondary"
              >
                Remove
              </LoadingButton>
            </span>
          )}
      </>
    );
  };

  return (
    <>
      <Box sx={{ px: 1 }}>
        <Grid
          container
          alignItems="center"
          gap={1}
          sx={styles.container}
          justifyContent="left"
        >
          {renderChips()}
          {value && value?.length > 0 && isEditable && (
            <Box
              component="i"
              className="fa-regular fa-chevron-down"
              sx={{ fontSize: 10 }}
            />
          )}
        </Grid>
      </Box>
      {open && (
        <Popper
          sx={styles.popper}
          open={open}
          ref={popperRef}
          anchorEl={anchorEl}
          placement="right-start"
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Paper sx={styles.popperPaper}>
              <Box>{renderMenu()}</Box>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}

      <DeleteAutomatedOptionDialog
        open={displayWarningMenu}
        handleClose={() => setDisplayWarningMenu(false)}
        handleConfirm={handleConfirmDeleteAutomatedField}
      />
    </>
  );
});

export default TableSelect;
