import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { OrganizationUserContext } from "contexts/Organization";
import { useContext, useEffect } from "react";
import theme from "styles/theme";

import { DISPLAYED_SIMILAR_BRANDS_DIALOG } from "utils/localStorage";
import { trackEvent } from "utils/tracking";

interface SimilarBrandsDialogProps {
  open: boolean;
  handleClose: () => void;
}

export default function SimilarBrandsDialog({
  open,
  handleClose,
}: SimilarBrandsDialogProps) {
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { currentOrg } = useContext(OrganizationUserContext);

  useEffect(() => {
    if (open) {
      trackEvent("Similar Brands Feature Dialog Displayed");
      localStorage.setItem(
        `${DISPLAYED_SIMILAR_BRANDS_DIALOG}-${currentOrg?.id}`,
        "true",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen={isMobileScreen}>
      <DialogTitle>
        <Grid container justifyContent="center">
          Browse More Brands ✨
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ p: 4 }}>
        Bento just launch a new feature! 🎉 Send more emails with our{" "}
        <strong>Similar Brands</strong> feature. Scroll down to the bottom of
        the brand details to see more brands related to the one you are viewing.
        <Grid item xs={12} container justifyContent="center" sx={{ mt: 2 }}>
          <Box
            component="img"
            src={
              "https://storage.googleapis.com/hatchways-crm.appspot.com/app-assets/similar_brands.png"
            }
            sx={{
              width: { xs: 300, md: 500 },
              border: `1px solid ${grey[200]}`,
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
