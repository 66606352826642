import moment from "moment";
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { CustomEvent } from "schemas/functions";

import {
  DISPLAY_SCHEDULED_DIALOG,
  SCHEDULED_DATE_EMAIL,
} from "utils/localStorage";
import { isBusinessHour, isIsoStringPriorToCurrentTime } from "utils/time";
import { trackEvent } from "utils/tracking";

import { OrganizationUserContext } from "./Organization";

export enum QuickSendAction {
  SEND = "send",
  SCHEDULE = "schedule",
}

export enum ScheduleTimeDialogSource {
  CHANGE = "change",
  BUSINESS_HOUR = "business_hour",
  SCHEDULE_BUTTON = "schedule_button",
}

interface QuickSendScheduleContextInterface {
  removeScheduled: boolean;
  setRemoveScheduled: Dispatch<SetStateAction<boolean>>;
  presetSendAt: string | null;
  shortenedSendAt: string;
  timezone: string;
  getUserScheduledAt: (sendAt: string | null) => string | null | undefined;
  hideBusinessHourDialog: boolean;
  isOutsideBusinessHour: (sendAt: null | string) => boolean;
  openBusinessHourDialog: boolean;
  setOpenBusinessHourDialog: Dispatch<SetStateAction<boolean>>;
  selectedIndex: number;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
  hasScheduled: boolean;
  handleRemoveScheduledAt: (e: CustomEvent) => void;
  scheduleDialogSource: ScheduleTimeDialogSource | null;
  setScheduleDialogSource: Dispatch<
    SetStateAction<ScheduleTimeDialogSource | null>
  >;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  removeScheduled: false,
  setRemoveScheduled: defaultContextMissingFunction,
  action: QuickSendAction.SEND,
  presetSendAt: "",
  shortenedSendAt: "",
  timezone: "",
  getUserScheduledAt: defaultContextMissingFunction,
  hideBusinessHourDialog: false,
  isOutsideBusinessHour: defaultContextMissingFunction,
  openBusinessHourDialog: false,
  setOpenBusinessHourDialog: defaultContextMissingFunction,
  selectedIndex: 0,
  setSelectedIndex: defaultContextMissingFunction,
  hasScheduled: false,
  handleRemoveScheduledAt: defaultContextMissingFunction,
  scheduleDialogSource: null,
  setScheduleDialogSource: defaultContextMissingFunction,
};

const QuickSendScheduleContext =
  createContext<QuickSendScheduleContextInterface>(defaultInterface);

interface QuickSendScheduleProviderProps {
  children: React.ReactNode;
}

const QuickSendScheduleProvider = ({
  children,
}: QuickSendScheduleProviderProps) => {
  const { emailSettings, currentOrg } = useContext(OrganizationUserContext);
  const [removeScheduled, setRemoveScheduled] = useState(false);
  const [openBusinessHourDialog, setOpenBusinessHourDialog] =
    useState<boolean>(false);
  const [scheduleDialogSource, setScheduleDialogSource] =
    useState<ScheduleTimeDialogSource | null>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const timezone = emailSettings?.timezone || moment.tz.guess();
  const presetSendAt = localStorage.getItem(
    `${SCHEDULED_DATE_EMAIL}-${currentOrg?.id}`,
  );
  let shortenedSendAt = "";
  if (presetSendAt && !isIsoStringPriorToCurrentTime(presetSendAt)) {
    shortenedSendAt = moment(presetSendAt).tz(timezone).format("MMM DD, hh A");
  } else {
    // If user's pre-saved scheduled is in the past, let them reset it.
    localStorage.removeItem(`${SCHEDULED_DATE_EMAIL}-${currentOrg?.id}`);
  }
  const hasScheduled = Boolean(shortenedSendAt && !removeScheduled);

  const hideBusinessHourDialog =
    localStorage.getItem(`${DISPLAY_SCHEDULED_DIALOG}-${currentOrg?.id}`) ===
    "true";

  const getUserScheduledAt = (sendAt: null | string) => {
    if (sendAt && !isIsoStringPriorToCurrentTime(sendAt)) {
      localStorage.setItem(
        `${SCHEDULED_DATE_EMAIL}-${currentOrg?.id}`,
        sendAt?.toString(),
      );
      setRemoveScheduled(false);
    }

    setScheduleDialogSource(null);
    return sendAt;
  };

  const checkEmailHour = (
    setOpenBusinessHourDialog: (openBusinessHourDialog: boolean) => void,
    timezone: string,
  ) => {
    const currentTime = moment().toISOString();
    if (!isBusinessHour(currentTime, timezone)) {
      setOpenBusinessHourDialog(true);
      return false;
    }
    return true;
  };

  const isOutsideBusinessHour = (sendAt: null | string) => {
    if (
      !sendAt &&
      !presetSendAt &&
      !hideBusinessHourDialog &&
      !checkEmailHour(setOpenBusinessHourDialog, timezone)
    ) {
      return true;
    }
    return false;
  };

  const handleRemoveScheduledAt = (e: CustomEvent) => {
    e.stopPropagation();
    trackEvent("Remove Scheduled At Button Clicked");
    localStorage.removeItem(`${SCHEDULED_DATE_EMAIL}-${currentOrg?.id}`);
    setRemoveScheduled(true);
    setSelectedIndex(0);
  };

  useEffect(() => {
    setSelectedIndex(hasScheduled ? 1 : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasScheduled]);

  return (
    <QuickSendScheduleContext.Provider
      value={{
        removeScheduled,
        setRemoveScheduled,
        presetSendAt,
        shortenedSendAt,
        timezone,
        getUserScheduledAt,
        hideBusinessHourDialog,
        isOutsideBusinessHour,
        openBusinessHourDialog,
        setOpenBusinessHourDialog,
        selectedIndex,
        setSelectedIndex,
        hasScheduled,
        handleRemoveScheduledAt,
        scheduleDialogSource,
        setScheduleDialogSource,
      }}
    >
      {children}
    </QuickSendScheduleContext.Provider>
  );
};

export { QuickSendScheduleProvider, QuickSendScheduleContext };
