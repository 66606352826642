const styles = {
  cardContainer: {
    pb: 4, // padding for saved brands
  },
  cardContainerMobile: {
    pb: 10,
  },
  brandsList: (hasTags?: boolean) => ({
    mt: {
      xs: 0,
      md: hasTags ? "150px" : "100px",
    },
    pb: { xs: 12, md: 0 },
  }),
};

export default styles;
