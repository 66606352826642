import { useAuth } from "@clerk/clerk-react";
import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useInView } from "react-intersection-observer";
import {
  AdditionalContactNotification,
  BrandNotification,
  UserRequest,
} from "schemas/dashboard";

import { fetcherAuth } from "utils/api";

import { AlertContext } from "./Alert";
import { OrganizationUserContext } from "./Organization";

const PER_PAGE = 20;

interface UserRequestsContextInterface {
  userRequests: UserRequest[];
  brandNotifications: BrandNotification[];
  contactNotifications: AdditionalContactNotification[];
  setUserRequests: Dispatch<SetStateAction<UserRequest[]>>;
  setBrandNotifications: Dispatch<SetStateAction<BrandNotification[]>>;
  setContactNotifications: Dispatch<
    SetStateAction<AdditionalContactNotification[]>
  >;
  userRequestRef: any;
  brandRef: any;
  contactRef: any;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const defaultContextMissingFunction = () => {
  throw new Error("context is missing");
};

const defaultInterface = {
  userRequests: [],
  brandNotifications: [],
  contactNotifications: [],
  setUserRequests: defaultContextMissingFunction,
  setBrandNotifications: defaultContextMissingFunction,
  setContactNotifications: defaultContextMissingFunction,
  userRequestRef: null,
  brandRef: null,
  contactRef: null,
  open: false,
  setOpen: defaultContextMissingFunction,
};

const UserRequestsContext =
  createContext<UserRequestsContextInterface>(defaultInterface);

interface UserRequestsProviderProps {
  children: React.ReactNode;
}

const UserRequestsProvider = ({ children }: UserRequestsProviderProps) => {
  const { getToken } = useAuth();
  const { setErrorAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);

  const [userRequests, setUserRequests] = useState<UserRequest[]>([]);
  const [brandNotifications, setBrandNotifications] = useState<
    BrandNotification[]
  >([]);
  const [contactNotifications, setContactNotifications] = useState<
    AdditionalContactNotification[]
  >([]);
  const [open, setOpen] = useState(false);

  const [userRequestRef, isUserRequestVisible] = useInView({
    rootMargin: "0px 0px",
  });
  const [brandRef, isBrandVisible] = useInView({
    rootMargin: "0px 0px",
  });
  const [contactRef, isContactVisible] = useInView({
    rootMargin: "0px 0px",
  });

  const [page, setPage] = useState({
    userRequests: 1,
    contactNotifications: 1,
    brandNotifications: 1,
  });
  const [total, setTotal] = useState({
    userRequests: 0,
    contactNotifications: 0,
    brandNotifications: 0,
  });
  const [loading, setLoading] = useState({
    userRequests: false,
    contactNotifications: false,
    brandNotifications: false,
  });

  const fetchUserRequest = async () => {
    if (!currentOrg?.id) return;
    setLoading((prev) => ({ ...prev, userRequests: true }));
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/user-requests?page=${page.userRequests}&per_page=${PER_PAGE}`,
        "GET",
      );
      if (page.userRequests === 1) {
        setUserRequests(res.userRequests);
      } else {
        setUserRequests((prev) => [...prev, ...res.userRequests]);
      }
      setTotal((prev) => ({ ...prev, userRequests: res.total }));
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading((prev) => ({ ...prev, userRequests: false }));
    }
  };

  const fetchBrandNotification = async () => {
    if (!currentOrg?.id) return;
    try {
      setLoading((prev) => ({ ...prev, brandNotifications: true }));
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/brand-notifications?page=${page.brandNotifications}&per_page=${PER_PAGE}`,
        "GET",
      );
      if (page.brandNotifications === 1) {
        setBrandNotifications(res.brandNotifications);
      } else {
        setBrandNotifications((prev) => [...prev, ...res.brandNotifications]);
      }
      setTotal((prev) => ({ ...prev, brandNotifications: res.total }));
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading((prev) => ({ ...prev, brandNotifications: false }));
    }
  };

  const fetchContactNotification = async () => {
    if (!currentOrg?.id) return;
    try {
      setLoading((prev) => ({ ...prev, brandNotifications: true }));
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/additional-contact-notifications?page=${page.contactNotifications}&per_page=${PER_PAGE}`,
        "GET",
      );
      if (page.contactNotifications === 1) {
        setContactNotifications(res.contactNotifications);
      } else {
        setContactNotifications((prev) => [
          ...prev,
          ...res.contactNotifications,
        ]);
      }
      setTotal((prev) => ({ ...prev, contactNotifications: res.total }));
    } catch (error) {
      setErrorAlert(error);
    } finally {
      setLoading((prev) => ({ ...prev, contactNotifications: false }));
    }
  };

  useEffect(() => {
    fetchUserRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, page.userRequests]);

  useEffect(() => {
    fetchBrandNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, page.brandNotifications]);

  useEffect(() => {
    fetchContactNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.id, page.contactNotifications]);

  useEffect(() => {
    if (isUserRequestVisible) {
      if (userRequests.length < total.userRequests && !loading.userRequests) {
        setPage((prev) => ({ ...prev, userRequests: prev.userRequests + 1 }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserRequestVisible]);

  useEffect(() => {
    if (isBrandVisible) {
      if (
        brandNotifications.length < total.brandNotifications &&
        !loading.brandNotifications
      ) {
        setPage((prev) => ({
          ...prev,
          brandNotifications: prev.brandNotifications + 1,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrandVisible]);

  useEffect(() => {
    if (isContactVisible) {
      if (
        contactNotifications.length < total.contactNotifications &&
        !loading.contactNotifications
      ) {
        setPage((prev) => ({
          ...prev,
          contactNotifications: prev.contactNotifications + 1,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrandVisible]);

  return (
    <UserRequestsContext.Provider
      value={{
        userRequests,
        setUserRequests,
        brandNotifications,
        setBrandNotifications,
        contactNotifications,
        setContactNotifications,
        userRequestRef,
        brandRef,
        contactRef,
        setOpen,
        open,
      }}
    >
      {children}
    </UserRequestsContext.Provider>
  );
};

export { UserRequestsProvider, UserRequestsContext };
