import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { DiscoverViewContext } from "contexts/DiscoverView";
import { OrganizationUserContext } from "contexts/Organization";
import { SubscriptionContext } from "contexts/Subscription";
import { UserRequestsContext } from "contexts/UserRequests";
import { useContext } from "react";
import { RequestStatus } from "schemas/dashboard";

import { fetcherAuth } from "utils/api";
import { trackEvent } from "utils/tracking";

import UserRequestsListFooter from "./Footer";
import SingleRequestCard from "./SingleRequestCard";
import styles from "./styles";

enum RequestType {
  user_requests = "user_requests",
  brand_notifications = "brand_notifications",
  contact_notifications = "contact_notifications",
}

const UserRequestsList = () => {
  const { setErrorAlert } = useContext(AlertContext);
  const { currentOrg } = useContext(OrganizationUserContext);
  const { getToken } = useAuth();
  const { setDiscoverTab } = useContext(DiscoverViewContext);
  const closeDialog = () => {
    setDiscoverTab(null);
  };

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { canMakeBrandRequest, setUpgradeDialogSource } =
    useContext(SubscriptionContext);

  const {
    userRequests,
    setUserRequests,
    brandNotifications,
    setBrandNotifications,
    contactNotifications,
    setContactNotifications,
    userRequestRef,
    brandRef,
    contactRef,
    setOpen,
  } = useContext(UserRequestsContext);

  const deleteUserRequest = async (userRequestId?: number) => {
    if (!currentOrg) return;
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg.id}/user-requests/${userRequestId}`,
        "DELETE",
      );
      setUserRequests((prev) =>
        prev.filter((userRequest) => userRequest.id !== userRequestId),
      );
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const deleteBrandNotifications = async (bentoBrandId?: number) => {
    if (!currentOrg || !bentoBrandId) return;
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg.id}/brand-notifications`,
        "DELETE",
        {},
        {
          bentoBrandId,
        },
      );
      setBrandNotifications((prev) =>
        prev.filter((request) => request.bentoBrandId !== bentoBrandId),
      );
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const deleteContactNotifications = async (bentoBrandId?: number) => {
    if (!currentOrg || !bentoBrandId) return;
    try {
      await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg.id}/additional-contact-notifications`,
        "DELETE",
        {},
        { bentoBrandId },
      );
      setContactNotifications((prev) =>
        prev.filter((request) => request.bentoBrandId !== bentoBrandId),
      );
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const getLabel = (status: RequestStatus, type: RequestType) => {
    const labels = {
      [RequestType.brand_notifications]: "Contact Found",
      [RequestType.contact_notifications]: "Contact Found",
      [RequestType.user_requests]: "Brand Found",
    };

    if (status === RequestStatus.found) {
      return labels[type];
    } else {
      return "Pending";
    }
  };

  const openRequest = () => {
    trackEvent("Request Brand Clicked");
    if (!canMakeBrandRequest()) {
      setUpgradeDialogSource("Hits Brand Request Limit");
      return;
    }
    setOpen(true);
  };

  return (
    <>
      {!isMobileScreen && (
        <Grid item xs="auto" sx={styles.header}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h6" sx={{ fontSize: 14 }}>
              REQUESTS
            </Typography>
            <IconButton sx={styles.close} onClick={closeDialog}>
              <Box component="i" className="fa-regular fa-xmark" />
            </IconButton>
          </Grid>
        </Grid>
      )}

      <Grid container sx={styles.container}>
        {userRequests?.map((request, index) => (
          <SingleRequestCard
            bentoBrand={request?.bentoBrand}
            requestWebsiteLink={request?.description}
            onDelete={() => deleteUserRequest(request.id)}
            status={request.status}
            label={getLabel(request.status, RequestType.user_requests)}
            ref={userRequestRef}
            displayedLength={userRequests?.length}
            index={index}
            key={index}
          />
        ))}
        {brandNotifications?.map((request, index) => (
          <SingleRequestCard
            bentoBrand={request?.bentoBrand}
            onDelete={() => deleteBrandNotifications(request.bentoBrandId)}
            status={request.status}
            label={getLabel(request.status, RequestType.brand_notifications)}
            ref={brandRef}
            displayedLength={brandNotifications?.length}
            index={index}
            key={index}
          />
        ))}
        {contactNotifications?.map((request, index) => (
          <SingleRequestCard
            bentoBrand={request?.bentoBrand}
            onDelete={() => deleteContactNotifications(request.bentoBrandId)}
            status={request.status}
            label={getLabel(request.status, RequestType.contact_notifications)}
            ref={contactRef}
            displayedLength={contactNotifications?.length}
            index={index}
            bcid={request?.bentoContactId}
            key={index}
          />
        ))}
      </Grid>

      <Grid
        container
        justifyContent="center"
        sx={isMobileScreen ? {} : styles.footer}
      >
        <Box sx={styles.newRequest} onClick={openRequest}>
          <strong>New Request + </strong>
        </Box>
        <UserRequestsListFooter />
      </Grid>
    </>
  );
};

export default UserRequestsList;
