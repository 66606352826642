import { blue, grey } from "@mui/material/colors";

const styles = {
  root: {
    position: "relative",
  },
  container: (tabOpen: boolean) => ({
    maxHeight: {
      xs: "calc(100vh - 292px)",
      md: tabOpen ? "calc(100vh - 242px)" : "calc(100vh - 205px)",
      lg: "calc(100vh - 205px)",
    },
    overflow: "auto",
  }),
  table: {
    borderLeft: `1px solid ${grey[300]}`,
  },
  snippet: {
    fontSize: 12,
    color: grey[700],
    width: 300,
    overflow: "hidden",
  },
  subject: {
    color: "black",
    mr: 0.5,
  },
  header: {
    position: "relative",
    zIndex: 201,
  },
  tableRow: (isNewRow: boolean) => ({
    backgroundColor: "white",
    "&:nth-last-of-type(2) td": { borderBottom: 0 },
    ...(isNewRow && { position: "sticky", bottom: "44px", zIndex: 200 }),
  }),
  info: {
    color: "info.main",
    textDecoration: "none",
    mb: 4,
    cursor: "pointer",
  },

  fetchMoreLoader: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%)",
    bottom: "12px",
    fontSize: 12,
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
  },
  highlightedNewRow: {
    backgroundColor: `${blue[50]}`,
  },
  totalRows: {
    position: "absolute",
    right: "75px",
    bottom: "12px",
    zIndex: 300,
    fontSize: 12,
    color: grey[800],
  },
};

export default styles;
